export default ({ scroll = () => {}, IOS17Filled = () => {} }) => {
  const configObj = {
    // GENERAL INFO
    name: "Mayesbrooks",
    domain: "mayesbrooks.com",
    slogan: "Properties for Investors",
    api: {
      apiUrl: "https://www.mayesbrooks.com",
      apiDevUrl: "http://localhost:5003",
    },

    // SUBSCRIPTIONS
    subscriptions: {
      admin: true,
      quotes: true,
      projects: true,
    },

    // CONTACT
    tel: {
      tel1: "02031055699",
      tel2: "07884246577",
    },
    email: {
      email1: "info@mayesbrooks.com",
    },

    // PUBLIC
    public: {
      favicon:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733708696/London%20Mile/public/favicon_clwgdg.ico",
      logo192:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733708696/London%20Mile/public/logo192_ujmodb.png",
      logo512:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733708696/London%20Mile/public/logo512_y55zw9.png",
      "apple-touch-icon":
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733708697/London%20Mile/public/apple-touch-icon_nybo9b.png",
      logoPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852408/London%20Mile/public/logos/logo_imcukn_flqyvw.png",
      authPagePic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852791/London%20Mile/public/img/auth-page_ipwhra_hdtcxk.jpg",
      albumPlaceholderPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852795/London%20Mile/public/img/album-placeholder_sjlxfs_u9xfx9.webp",
      checkatradeLogoPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852418/London%20Mile/public/logos/ctlogo_rkfe6b_xyt5wt.webp",
      attachments: [
        {
          filename: "London Mile - Public Liability Insurance.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852710/London%20Mile/documents/London_Mile_-_Public_Liability_Insurance_dyqkhr.pdf",
        },
        {
          filename: "Warranty from London Mile.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852713/London%20Mile/documents/Warranty_from_London_Mile_utq1fw.pdf",
        },
      ],
    },

    // CHAT
    chat: {
      showWidget: true,
      avatar:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851192/London%20Mile/public/img/chat_xl0fej_yqt20m.jpg",
      person: "Ibi",
      tel: "+447884246577",
      message: `Hello there! \n \nHow can we help?`,
      status: `Typically replies within a few minutes`,
    },

    // SOCIALS
    socials: {
      instagram: "mayesbrooks",
    },

    // TYPE EFFECTS
    textType: [
      "Bathrooms",
      "Kitchens",
      "Flooring",
      "Extensions",
      "Demolition",
      "Plumbing",
      "Heating",
      "Pipe Work",
    ],

    // HEADER
    nav: {
      blur: "1px",
      logo: "",
      dark: true,
      styles: { height: "40px" },
      fadeOnPaths: ["/"],
    },

    // MAIN PIC
    mainPics: {
      main: {
        carousel: [
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852905/London%20Mile/public/img/1_fdy4ql_ptadf8.jpg",
            altText: "Kitchen Fitting",
            key: 1,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852900/London%20Mile/public/img/2_qbna35_xelgak.jpg",
            altText: "Bathroom Fitting",
            key: 2,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852897/London%20Mile/public/img/3_uz8szn_kfvyjw.jpg",
            altText: "Living Rooms",
            key: 3,
          },
        ],
      },
      copy1: {
        imgPath:
          "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852985/London%20Mile/public/img/1_s73fxg_gnabr8.jpg",
        parallax: false,
        noShadow: true,
        imgBottom: "0%",
        sloganCenter: true,
        slogan: "Commercial & Domestion Trade You Can Trust!",
      },
    },

    // WORKING HOURS
    workingHrs: {
      days: {
        Mon: { start: 32400000, end: 64800000 },
        Tue: { start: 32400000, end: 64800000 },
        Wed: { start: 32400000, end: 64800000 },
        Thu: { start: 32400000, end: 64800000 },
        Fri: { start: 32400000, end: 64800000 },
        Sat: { start: 32400000, end: 64800000 },
        Sun: { start: 0, end: 0 },
      },
      interval: 1800000,
    },

    // INFOS
    infos: {
      infos1: [
        {
          header: "Commercial Work",
          body: "We have invested in all aspects to ensure that we offer a market leading approach to design and build",
          icon: (_) => IOS17Filled({ name: "building", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733853057/London%20Mile/public/img/infos1-1_u8bagf_c3brte.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Domestic Work",
          body: "Highly professional company with a passion for innovative designs and quality construction. We believe strongly in understanding the needs of our customers, and our business is developed specifically to meet those needs.",
          icon: (_) => IOS17Filled({ name: "home", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733853052/London%20Mile/public/img/infos1-2_vfhjqe_qewd2t.jpg",
          alt: "infos1-2",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Free Estimates",
          body: "Get your free quote today. Our team are always ready to help. Talk to us or call us directly for an immediate quote.",
          icon: (_) => IOS17Filled({ name: "money", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733853046/London%20Mile/public/img/infos1-3_vbbohb_iqskjd.jpg",
          alt: "infos1-3",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
      ],
      infos2: [
        {
          header: "Warranty Included",
          body: "We take pride in the quality of our work and the satisfaction of our clients. That's why we offer a 1 year warranty on all of our construction projects. You can trust that the work we do for you will be completed to the highest standards and will meet your expectations. If any issues arise within 12 months of completion, we will work to quickly resolve them and ensure your complete satisfaction.",
          icon: (_) => IOS17Filled({ name: "book", size: "40" }),
        },
        {
          header: "Insurance",
          body: "The safety and security of our clients and workers are our top priorities. That's why we take all necessary steps to ensure we are fully insured against any potential accidents or damages. We have comprehensive liability insurance as well as workers' compensation insurance to protect our clients and our team in case of any unforeseen events. We are fully committed to providing you with the highest standards of safety, quality and customer service.",
          icon: (_) => IOS17Filled({ name: "hearts", size: "40" }),
        },
      ],
      infos3: {
        header: "Our Partners",
        body: "Get Discounts From Our Reputable Partners",
        partners: [
          {
            header: "Toolstation",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852273/London%20Mile/public/logos/tslogo_zdf6vu_txbyrb.png",
          },
          {
            header: "Wickes",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852233/London%20Mile/public/logos/wlogo_f02fzb_xycqpa.png",
          },
          {
            header: "Howdens",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852415/London%20Mile/public/logos/hlogo_szpc4p_ps84lh.png",
          },
          {
            header: "Topps Tiles",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852258/London%20Mile/public/logos/ttlogo_onp9cl_bxxujf.png",
          },
          {
            header: "Screwfix",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852276/London%20Mile/public/logos/sflogo_hlgmy6_jvxdqn.png",
          },
          {
            header: "Crown",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852423/London%20Mile/public/logos/clogo_xdolzp_scoy1u.png",
          },
          {
            header: "BQ",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852430/London%20Mile/public/logos/bqlogo_ijvpma_oevhye.png",
          },
          {
            header: "National Lighting",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852399/London%20Mile/public/logos/nllogo_mbkd8r_gqut4h.png",
          },
          {
            header: "Travis Perkins",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852229/London%20Mile/public/logos/tplogo_rca9pr_wcetpu.png",
          },
          {
            header: "Selco Builders Warehouse",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852334/London%20Mile/public/logos/sbwlogo_j2uot8_m7lhpb.png",
          },
          {
            header: "Al-Murad Tiles",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852453/London%20Mile/public/logos/amlogo_kkfswo_okkqlh.png",
          },
          {
            header: "KBS",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852411/London%20Mile/public/logos/kbslogo_qntazw_whp132.png",
          },
          {
            header: "Victorian Plumbing",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733852256/London%20Mile/public/logos/vp_d7egpr_pcvkcz.png",
          },
        ],
      },
    },

    // REVIEWS
    reviews: [
      {
        title: "Renovation and refit of bathroom",
        body: "From start to finish this was the easiest and best experience we’ve had with tradesmen in London. Work finished right on time. Men on site were on time every day; worked long and hard. Always courteous and spent a lot of time making sure they kept areas as clean and tidy as possible when they left. Work itself was to a very high standard and there were no hidden extras when it came to the final bill. Estimate was detailed and accurate. Would have zero hesitation in recommending to others or using again for further work.",
      },
      {
        title: "Refurb of whole house",
        body: "Vugar and team are an excellent, hard working team. We were impressed at the speed, quality and communication involved in the build. Vugar also provides helpful suggestions and keeps his clients informed of changes. All in all, a very positive experience.",
      },
      {
        title: "Bathroom refurbishment",
        body: "The bathroom fitters, Sergiu and Eduard, were amazing - in every way! They were professional, punctual, reliable, accommodating, polite and respectful, modest, clean and tidy, and work fast. I am a perfectionist, but their workmanship is superb - impeccable! Immaculate perfection! Vugar, the director, went the extra mile to help me choose tiles for my accent wall, and I am grateful to have benefited from his expertise and trade discount. This team is delightful to work with, with first-class qualities and workmanship. They were incredible - from start to finish! My bathroom looks stunning!",
      },
      {
        title: "Bathroom Reno / Overall Home Maintenance and Improvement",
        body: "I have had the pleasure of working with Vugar and his teams on multiple projects this past year. The works have all been amazing, the teams always provided the highest quality of finish, showed up on time, kept the work space tidy, was very respectful of my home. Vugar is attentive, easy to contact, provides fair pricing, has amazing attention to detail, prides himself on high quality structural and aesthetic finish, is open minded and finds solutions to all my odd requests. I will contact this company again for future works without a question.",
      },
      {
        title: "Creating new alcove in chimney stack and new ceiling lights",
        body: "Good professional service - both elements of work were done to a high standard and to time Would definitely use again in the future for any other work needed on our property",
      },
      {
        title: "Complete renovation of bathroom",
        body: "Vugar and his team did an amazing job renovating our bathroom. Nothing was too much hassle and they work incredibly hard. The team are invested in getting the best results throughout the job, consulting on how to get the best finish/deal with any issues that come up efficiently. Initial quote was reliable, no slippage. Left the space spotless after the job. 10/10.",
      },
      {
        title: "Building wardrobes and stripping",
        body: "This is the second time we have used London Mile for building and decorating. This time they built wardrobes from scratch and fully painted them. They also stripped old wallpaper and plaster off walls and ceiling in a large living room dining room. Replastered and repainted, including painting woodwork. The work was all completed within 8 days, they arrived promptly to start work at 8am, and worked until at least 4pm every day. They pay attention to every little detail and ensure we were happy. Done to a very high standard. Will be using again and recommending to family/friends.",
      },
    ],

    // INPUT OPTIONS
    inputsOptions: {
      sortByOptions: [
        { value: "", label: "Select" },
        { value: "createdAt:asc", label: "Oldest" },
        { value: "createdAt:desc", label: "Newest" },
        { value: "updatedAt:asc", label: "Updated - oldest" },
        { value: "updatedAt:desc", label: "Updated - soonest" },
      ],
    },

    // EXTRAS
    extras: {
      locations: "London, Belvedere, Orpington, Folkestone, Kensington, Greater London",
      checkatrade: {
        name: "London Mile",
        urlName: "londonmileltd",
        link: "https://www.checkatrade.com/trades/londonmileltd",
        rating: "10/10 Rating",
      },
    },
  };

  return configObj;
};
