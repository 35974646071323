///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, CustomInput, InputGroup, CustomInputGroupText } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Workers = ({ workers, selectedWorkers, setInputs }) => {
  // onChange functions
  const onWorkersChange = (e) => {
    try {
      setInputs((c) => ({ ...c, workers: [...c.workers, { id: e.target.value }] }));
    } catch (e) {
      console.error(e);
      setInputs((c) => ({ ...c, workers: [] }));
    }
  };

  const onRateChange = (e, i, w) => {
    try {
      const rate = Number(e.target.value);
      const arr = selectedWorkers;
      arr[i] = { ...w, rate };

      setInputs((c) => ({ ...c, workers: arr }));
    } catch (e) {
      console.error(e);
    }
  };

  const onDaysChange = (e, i, w) => {
    try {
      const days = Number(e.target.value);
      const arr = selectedWorkers;
      arr[i] = { ...w, days };

      setInputs((c) => ({ ...c, workers: arr }));
    } catch (e) {
      console.error(e);
    }
  };

  const onPayerChange = (e, i, w) => {
    try {
      const arr = selectedWorkers;
      arr[i] = { ...w, payer: e.target.value };

      setInputs((c) => ({ ...c, workers: arr }));
    } catch (e) {
      console.error(e);
    }
  };

  // onClick functions
  const onRemoveWokerClick = (i) => {
    try {
      const arr = selectedWorkers;
      arr.splice(i, 1);
      setInputs((c) => ({ ...c, workers: [...arr] }));
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <br />
      <h4 className="t-align-c">Workers</h4>

      <FormGroup>
        <CustomInput type="select" name="workers" value={""} onChange={onWorkersChange}>
          <option value="">Select</option>
          {workers?.map((w, i) => (
            <option key={i} value={w?.id} label={w?.name} />
          ))}
        </CustomInput>
      </FormGroup>

      {selectedWorkers?.map((w, i) => (
        <div key={i} className="p-b-xs">
          <InputGroup size="sm">
            <CustomInputGroupText>
              <div onClick={(_) => onRemoveWokerClick(i)} style={{ cursor: "pointer" }}>
                {w?.id ? w?.id : "RMV"}
              </div>
            </CustomInputGroupText>
            <CustomInput
              value={w?.rate}
              onChange={(e) => onRateChange(e, i, w)}
              type="number"
              placeholder="Rate"
            ></CustomInput>
            <CustomInput
              value={w?.days}
              onChange={(e) => onDaysChange(e, i, w)}
              type="number"
              placeholder="Days"
            ></CustomInput>
            <CustomInput type="select" value={w?.payer} onChange={(e) => onPayerChange(e, i, w)}>
              <option value="">Select</option>
              {workers?.map((w, i) => {
                if (w.payer) return <option key={i} value={w?.id} label={w?.name} />;
              })}
            </CustomInput>
            <CustomInputGroupText>£{Number(w?.rate * w?.days).toFixed(2)}</CustomInputGroupText>
          </InputGroup>
        </div>
      ))}
    </>
  );
};

// Can't use memo because of: selectedWorkers
export default Workers;
