///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { Col, CustomButton, CustomInput, Form, FormGroup, Label, Row } from "@ibiliaze/reactstrap";
import { DropZone } from "@ibiliaze/react-dropzone";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Media = ({ admin, putAdmin, uploadImg }) => {
  // State
  const [inputs, setInputs] = useState({
    comparisonOneTitle: "",
    comparisonOneDescription: "",
    comparisonTwoTitle: "",
    comparisonTwoDescription: "",
  });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Helper functions
  const onUpload = async (file, comparisonId) => {
    try {
      await uploadImg({
        file,
        path: `/${comparisonId}?folder=comparisons`,
        callback: async (imgPath) => {
          await putAdmin({ comparisons: { ...admin?.admin?.comparisons, [comparisonId]: imgPath } });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs((c) => ({ ...c, ...admin?.admin?.comparisons }));
    } catch (e) {
      console.error(e);
    }
  }, [admin?.admin]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await putAdmin({ comparisons: { ...admin?.admin?.comparisons, ...inputs } });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <FormGroup>
          <CustomButton color="primary" black type="submit">
            Save
          </CustomButton>
        </FormGroup>

        <Col md={6}>
          <FormGroup floating>
            <CustomInput
              placeholder="Comparison One Title"
              name="comparisonOneTitle"
              value={inputs.comparisonOneTitle}
              onChange={onInputsChange}
            />
            <Label>Comparison One Title</Label>
          </FormGroup>
          <FormGroup>
            <CustomInput
              type="textarea"
              rows={5}
              placeholder="Comparison One Description"
              name="comparisonOneDescription"
              value={inputs.comparisonOneDescription}
              onChange={onInputsChange}
            />
          </FormGroup>

          <h4 className="t-align-c">Comparison Image 1 - Before</h4>
          <DropZone
            onUpload={onUpload}
            id="comparisonOne-1"
            imgSrc={admin?.admin?.comparisons?.["comparisonOne-1"] || c.public.albumPlaceholderPic}
          />

          <h4 className="t-align-c">Comparison Image 1 - After</h4>
          <DropZone
            onUpload={onUpload}
            id="comparisonOne-2"
            imgSrc={admin?.admin?.comparisons?.["comparisonOne-2"] || c.public.albumPlaceholderPic}
          />
        </Col>

        <Col md={6}>
          <FormGroup floating>
            <CustomInput
              placeholder="Comparison Two Title"
              name="comparisonTwoTitle"
              value={inputs.comparisonTwoTitle}
              onChange={onInputsChange}
            />
            <Label>Comparison Two Title</Label>
          </FormGroup>
          <FormGroup>
            <CustomInput
              type="textarea"
              rows={5}
              placeholder="Comparison Two Description"
              name="comparisonTwoDescription"
              value={inputs.comparisonTwoDescription}
              onChange={onInputsChange}
            />
          </FormGroup>
          <h4 className="t-align-c">Comparison Image 2 - Before</h4>
          <DropZone
            onUpload={onUpload}
            id="comparisonTwo-1"
            imgSrc={admin?.admin?.comparisons?.["comparisonTwo-1"] || c.public.albumPlaceholderPic}
          />
          <h4 className="t-align-c">Comparison Image 2 - After</h4>
          <DropZone
            onUpload={onUpload}
            id="comparisonTwo-2"
            imgSrc={admin?.admin?.comparisons?.["comparisonTwo-2"] || c.public.albumPlaceholderPic}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Media;
