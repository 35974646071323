///////////////////////////////////////////////////////////////////////////////////MODULES
import { memo } from "react";
import {
  CustomListGroup,
  CustomListGroupItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ListGroupItemText,
  CustomPagination,
  CentredSpinner,
} from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const List = ({
  offcanvas,
  toggleOffcanvas,
  page,
  onPageClick,
  onClick,
  list,
  headerKeys,
  header,
  loading,
}) => {
  // JSX
  return (
    <Offcanvas direction="start" fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
      <OffcanvasHeader toggle={toggleOffcanvas}>{header}</OffcanvasHeader>
      <OffcanvasBody>
        <CustomPagination page={page} onPageClick={onPageClick} />

        {loading ? (
          <CentredSpinner />
        ) : (
          <CustomListGroup>
            {list.map((listItem, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onClick(listItem)}>
                <ListGroupItemText>
                  {headerKeys.map((k) => `${listItem?.[k] || ""}`).join("\n")}
                </ListGroupItemText>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default memo(List);
