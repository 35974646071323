///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import {
  TextButton,
  CustomListGroup,
  CustomListGroupItem,
  ButtonGroup,
  Nav,
  NavItem,
  NavLink,
  CustomButton,
} from "@ibiliaze/reactstrap";
import { WindowButton, download, Fluency } from "@ibiliaze/react-base";
//////////////////////////////////////////////////////////////////////////////////////////

const Prospects = ({
  adminInputs,
  setSearchFilter,
  prospects,
  page,
  onReachOutClick,
  onOpenLinkClick,
  onProspectClick,
  onAddClick,
  toggleTemplateModal,
}) => {
  // State
  const [activeTab, setActiveTab] = useState(false);

  // onClick functions
  const onNavClick = (tab) => {
    try {
      setActiveTab(tab);
      setSearchFilter((c) => ({ ...c, account: tab }));
    } catch (e) {
      console.error(e);
    }
  };

  const onDownloadClick = (type) => {
    try {
      if (type === "JSON") {
        download("prospects", JSON.stringify(prospects, null, 2));
      } else if (type === "CSV") {
        const keys = ["handle", "name", "brand", "reactOutLevel"];
        let csv = keys.join(",") + "\n";

        prospects.forEach((item) => {
          const row = keys.map((key) => item[key]).join("	"); // There is a tab here, not a space
          csv += row + "\n";
        });

        download("prospects", csv);
      }
    } catch (e) {
      console.error(e);
    }
  };

  //JSX
  return (
    <>
      <Nav tabs>
        {adminInputs?.accounts?.map((acc, i) => (
          <NavItem key={i} onClick={(_) => onNavClick(acc?.name)}>
            <NavLink href="#" active={activeTab === acc?.name ? true : false}>
              {acc?.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      {!!activeTab && (
        <>
          <br />
          <CustomButton color="primary" size="sm" onClick={toggleTemplateModal}>
            Template
          </CustomButton>
          <br />
          <br />
        </>
      )}

      <CustomListGroup className="p-b-m">
        {prospects?.map((p, i) => (
          <CustomListGroupItem key={i} red={p?.tag === "Unfit"} green={p?.tag === "Potential"}>
            <span className="t-gray t-f-s-ss">{i + 1 + 50 * (page - 1 || 0)}</span>
            <span className="c-p" onClick={(_) => onReachOutClick(p)}>
              {p?.reactOutLevel === 3 ? (
                <Fluency name="full-battery--v1" size="64" />
              ) : p?.reactOutLevel === 2 ? (
                <Fluency name="medium-battery" size="64" />
              ) : p?.reactOutLevel === 1 ? (
                <Fluency name="low-battery--v2" size="64" />
              ) : (
                <Fluency name="empty-battery--v2" size="64" />
              )}
            </span>
            <span onClick={(_) => onOpenLinkClick(p)} className="c-p">
              {p?.type === "Instagram" ? (
                <Fluency name="instagram-new" size="64" />
              ) : p?.type === "X" ? (
                <Fluency name="twitter-circled" size="64" />
              ) : p?.type === "LinkedIn" ? (
                <Fluency name="linkedin" size="64" />
              ) : p?.type === "TikTok" ? (
                <Fluency name="tiktok" size="64" />
              ) : p?.type === "Facebook" ? (
                <Fluency name="facebook-circled" size="64" />
              ) : p?.type === "Email" ? (
                <Fluency name="mail" size="64" />
              ) : (
                <Fluency name="user-male-circle" size="64" />
              )}
            </span>{" "}
            <TextButton onClick={(_) => onProspectClick(p)}>{p?.handle}</TextButton>
          </CustomListGroupItem>
        ))}
      </CustomListGroup>

      {!!activeTab && (
        <ButtonGroup className="m-b-m" size="sm">
          <CustomButton color="primary" onClick={(_) => onDownloadClick("JSON")}>
            Download JSON
          </CustomButton>
          <CustomButton color="primary" onClick={(_) => onDownloadClick("CSV")}>
            Download CSV
          </CustomButton>
        </ButtonGroup>
      )}

      <WindowButton onClick={onAddClick}>Add</WindowButton>
    </>
  );
};

export default Prospects;
