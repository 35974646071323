///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "./styles";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from "./Header";
import Details from "./Details";
import Invoice from "./Invoice/Invoice";
import Footer from "./Footer";
//////////////////////////////////////////////////////////////////////////////////////////

const Quote = ({ admin, details, inputs, setInputs, id, quoteUrl }) => {
  // State
  const [total, setTotal] = useState(0);

  // Lifecycle Hooks
  useEffect(() => {
    try {
      let totalPrice = 0;

      details?.forEach((quote) => {
        const price = Number(quote.details.price);
        totalPrice += price;
      });

      setTotal(totalPrice);
    } catch (e) {
      console.error(e);
    }
  }, [details]);

  // JSX
  return (
    <div
      style={{
        background: "white",
        ...styles.textStyles,
      }}
    >
      <Header inputs={inputs} id={id} />

      <table borderless style={{ ...styles.tableStyles, ...styles.textStyles }}>
        {!inputs?.invoice ? (
          <Details details={details} />
        ) : (
          <Invoice total={total} inputs={inputs} setInputs={setInputs} id={id} quoteUrl={quoteUrl} />
        )}
      </table>

      <br />
      <div style={{ padding: "10px", ...styles.trStyles, fontSize: "large", textAlign: "right" }}>
        Total: £{Number(total).toFixed(2)}
      </div>

      <br />
      <br />

      <Footer inputs={inputs} admin={admin} />
    </div>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

export default connect(mapStateToProps)(memo(Quote));
