////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { CustomCarousel } from "@ibiliaze/reactstrap";
import { FadeIn, TransformYDown } from "@ibiliaze/react-intersect";
import { Section } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Reviewers from "./Reviewers";
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = () => (
  <Section id="reviews-section" className="custom-page p-t-xl p-b-xl">
    <FadeIn>
      <h1 className="t-align-c">Reviews</h1>
    </FadeIn>
    <TransformYDown>
      <CustomCarousel items={c.reviews} fade={false} indicators={false} />
    </TransformYDown>
    <TransformYDown>
      <Reviewers />
    </TransformYDown>
  </Section>
);

export default Reviews;
