///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from '@ibiliaze/react-base';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/prospect
export const getProspectsSuccess = prospects => ({ type: types.GET_PROSPECTS_SUCCESS, prospects });
export const getProspectsFailure = errorMessage => ({ type: types.GET_PROSPECTS_FAILURE, errorMessage });
export const getProspects = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/prospect` : `${resolveHost()}/api/prospect${endpoint}`);

    dispatch(getProspectsSuccess(res.data));
  } catch (e) {
    dispatch(getProspectsFailure(e.message));
  }
};

// POST /api/prospect
export const postProspectSuccess = prospect => ({ type: types.POST_PROSPECT_SUCCESS, prospect });
export const postProspectFailure = errorMessage => ({ type: types.POST_PROSPECT_FAILURE, errorMessage });
export const postProspect = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the prospect...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/prospect`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_PROSPECT_SUCCESS));
    dispatch(postProspectSuccess(res.data.prospect));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_PROSPECT_FAILURE));
    dispatch(postProspectFailure(e.message));
  }
};

// PUT /api/prospect
export const putProspectSuccess = prospect => ({ type: types.PUT_PROSPECT_SUCCESS, prospect });
export const putProspectFailure = errorMessage => ({ type: types.PUT_PROSPECT_FAILURE, errorMessage });
export const putProspect = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the prospect...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/prospect/${data.prospectId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_PROSPECT_SUCCESS));
    dispatch(putProspectSuccess(res.data.prospect));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_PROSPECT_FAILURE));
    dispatch(putProspectFailure(e.message));
  }
};

// DELETE /api/prospect
export const deleteProspectSuccess = prospect => ({ type: types.DELETE_PROSPECT_SUCCESS, prospect });
export const deleteProspectFailure = errorMessage => ({ type: types.DELETE_PROSPECT_FAILURE, errorMessage });
export const deleteProspect = prospectId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the prospect...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/prospect/${prospectId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_PROSPECT_SUCCESS));
    dispatch(deleteProspectSuccess(res.data.prospect));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_PROSPECT_FAILURE));
    dispatch(deleteProspectFailure(e.message));
  }
};
