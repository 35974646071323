/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////PROSPECTS DEFAULT STATE
const prospectsDefaultState = [];
/////////////////////////////////////////////////////////////////////////PROSPECTS REDUCER
const prospects = (state = prospectsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_PROSPECTS_SUCCESS:
      return Array.isArray(action.prospects) ? [...action.prospects] : [action.prospects];
    case types.GET_PROSPECTS_FAILURE:
      return [...state];

    // POST request
    case types.POST_PROSPECT_SUCCESS:
      return [...state, action.prospect];
    case types.POST_PROSPECT_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_PROSPECT_SUCCESS:
      return state.map(prospect => {
        if (prospect._id === action.prospect._id) {
          return { ...prospect, ...action.prospect };
        } else {
          return prospect;
        }
      });
    case types.PUT_PROSPECT_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_PROSPECT_SUCCESS:
      return state.filter(prospect => prospect._id !== action.prospect._id);
    case types.DELETE_PROSPECT_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default prospects;
