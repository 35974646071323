///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, memo } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  CustomButton,
  CustomInput,
  Spinner,
  InputGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  CustomListGroup,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getQuotes } from "../../actions/quotes";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import List from "../Layout/List";
//////////////////////////////////////////////////////////////////////////////////////////

const Quotes = ({ setInputs, quoteRef, quotes, getQuotes }) => {
  // State
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [loading, setLoading] = useState({ getQuotes: false });
  const [quoteRefSearch, setQuoteRefSearch] = useState("");

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // onChange functions
  const onQuoteRefSearchChange = (e) => setQuoteRefSearch(e.target.value);

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      e.preventDefault();

      setLoading((c) => ({ ...c, getQuotes: true }));
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getQuotes(`?ref=${quoteRefSearch}&limit=10&skip=${10 * (goToPage - 1)}`);
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onSearchQuotesClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, getQuotes: true }));
      setPage(1);
      await getQuotes(`?ref=${quoteRefSearch}&limit=10&skip=0`);
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onQuoteClick = (quote) => {
    try {
      setInputs((c) => ({
        ...c,
        quoteRef: quote?.ref,
        name: quote?.name,
        address: quote?.inputs?.address,
        postcode: quote?.postcode,
      }));
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      toggleOffcanvas();
    }
  };

  const onSelectedQuoteClick = () => {
    try {
      window.open(`/quotes?ref=${quoteRef}`);
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <List
        offcanvas={offcanvas}
        toggleOffcanvas={toggleOffcanvas}
        header="Quotes"
        list={quotes}
        headerKeys={["name", "ref", "tag"]}
        page={page}
        onPageClick={onPageClick}
        onClick={onQuoteClick}
        loading={loading.getQuotes}
      />

      <FormGroup>
        <InputGroup>
          <CustomInput
            placeholder="Quote Ref"
            name="quoteRef"
            value={quoteRefSearch}
            onChange={onQuoteRefSearchChange}
          />
          <CustomButton
            disabled={loading.getQuotes ? true : false}
            color="primary"
            black
            onClick={onSearchQuotesClick}
          >
            {loading.getQuotes ? <Spinner type="grow" size="sm" /> : "Search"}
          </CustomButton>
        </InputGroup>
      </FormGroup>

      {!!quoteRef && typeof quoteRef === "string" && (
        <FormGroup>
          <CustomListGroup>
            <CustomListGroupItem onClick={onSelectedQuoteClick}>
              <ListGroupItemHeading>{quoteRef}</ListGroupItemHeading>
            </CustomListGroupItem>
          </CustomListGroup>
        </FormGroup>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ quotes: state.quotes });

export default connect(mapStateToProps, { getQuotes })(memo(Quotes));
