///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import {
  CustomInput,
  FormGroup,
  Label,
  CustomButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  CloseButton,
  ListGroup,
  ListGroupItem,
} from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Settings = ({ settingsModal, toggleSettingsModal, adminInputs, setAdminInputs, onSaveUserClick }) => {
  // State
  const [accountName, setAccountName] = useState("");
  const [nicheName, setNicheName] = useState("");

  // onChange functions
  const onAccountNameChange = (e) => setAccountName(e.target.value);
  const onNicheNameChange = (e) => setNicheName(e.target.value);

  // onClick functions
  const onAddAccountClick = (_) => {
    try {
      setAdminInputs((c) => ({
        ...c,
        accounts: [...(adminInputs?.accounts || undefined), { name: accountName, template: "" }],
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const onAddNicheClick = (_) => {
    try {
      setAdminInputs((c) => ({ ...c, niches: [...(adminInputs?.niches || undefined), nicheName] }));
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveAccClick = (i) => {
    try {
      const accounts = adminInputs?.accounts;
      accounts.splice(i, 1);

      setAdminInputs((c) => ({ ...c, accounts }));
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveNicheClick = (i) => {
    try {
      const niches = adminInputs?.niches;
      niches.splice(i, 1);

      setAdminInputs((c) => ({ ...c, niches }));
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Modal isOpen={settingsModal} toggle={toggleSettingsModal}>
      <ModalHeader toggle={toggleSettingsModal}>Settings</ModalHeader>
      <ModalBody>
        <h4 className="t-align-c">Accounts</h4>

        <ListGroup>
          {adminInputs?.accounts?.map((acc, i) => (
            <ListGroupItem key={i}>
              {acc?.name}
              <CloseButton bsSize="sm" onClick={(_) => onRemoveAccClick(i)} style={{ float: "right" }} />
            </ListGroupItem>
          ))}
        </ListGroup>
        <br />

        <FormGroup>
          <Label>Account Name</Label>
          <InputGroup>
            <CustomInput
              required
              placeholder="Account Name"
              value={accountName}
              onChange={onAccountNameChange}
            />
            <CustomButton color="primary" onClick={onAddAccountClick}>
              Add account
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <br />
        <h4 className="t-align-c">Niches</h4>

        <ListGroup>
          {adminInputs?.niches?.map((niche, i) => (
            <ListGroupItem key={i}>
              {niche}
              <CloseButton bsSize="sm" onClick={(_) => onRemoveNicheClick(i)} style={{ float: "right" }} />
            </ListGroupItem>
          ))}
        </ListGroup>
        <br />

        <FormGroup>
          <Label>Niche Name</Label>
          <InputGroup>
            <CustomInput required placeholder="Niche Name" value={nicheName} onChange={onNicheNameChange} />
            <CustomButton color="primary" onClick={onAddNicheClick}>
              Add niche
            </CustomButton>
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pos-b-s">
        <CustomButton color="primary" onClick={onSaveUserClick} className="w-100">
          Save
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
};

export default Settings;
