///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import {
  CustomButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  FormGroup,
  CustomInput,
  Label,
} from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const TemplateModal = ({
  templateModal,
  toggleTemplateModal,
  onSaveUserClick,
  searchFilter,
  setAdminInputs,
  adminInputs,
}) => {
  // State
  const [template, setTemplate] = useState("");

  // onChange functions
  const onTemplateChange = (e) => {
    try {
      setTemplate(e.target.value);

      const account = searchFilter?.account;
      setAdminInputs((c) => ({
        ...c,
        accounts: [...c.accounts].map((acc) =>
          acc.name === account ? { ...acc, template: e.target.value } : acc
        ),
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setTemplate(adminInputs.accounts.find((acc) => acc.name === searchFilter.account).template);
    } catch (e) {
      console.error(e);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  // JSX
  return (
    <Modal isOpen={templateModal} toggle={toggleTemplateModal}>
      <ModalHeader toggle={toggleTemplateModal}>DM Template</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Template</Label>
          <CustomInput
            type="textarea"
            rows="6"
            placeholder="DM Template"
            value={template}
            onChange={onTemplateChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pos-b-s">
        <ButtonGroup className="w-100">
          <CustomButton color="primary" onClick={onSaveUserClick}>
            Save
          </CustomButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default TemplateModal;
