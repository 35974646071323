///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/project
export const getProjectsSuccess = (projects) => ({ type: types.GET_PROJECTS_SUCCESS, projects });
export const getProjectsFailure = (errorMessage) => ({ type: types.GET_PROJECTS_FAILURE, errorMessage });
export const getProjects = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/project` : `${resolveHost()}/api/project${endpoint}`
    );

    dispatch(getProjectsSuccess(res.data));
  } catch (e) {
    dispatch(getProjectsFailure(e.message));
  }
};

// POST /api/project
export const postProjectSuccess = (project) => ({ type: types.POST_PROJECT_SUCCESS, project });
export const postProjectFailure = (errorMessage) => ({ type: types.POST_PROJECT_FAILURE, errorMessage });
export const postProject = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the project...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/project`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_PROJECT_SUCCESS));
    dispatch(postProjectSuccess(res.data.project));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_PROJECT_FAILURE)
    );
    dispatch(postProjectFailure(e.message));
  }
};

// PUT /api/project
export const putProjectSuccess = (project) => ({ type: types.PUT_PROJECT_SUCCESS, project });
export const putProjectFailure = (errorMessage) => ({ type: types.PUT_PROJECT_FAILURE, errorMessage });
export const putProject = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the project...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/project/${data.projectId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_PROJECT_SUCCESS));
    dispatch(putProjectSuccess(res.data.project));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_PROJECT_FAILURE));
    dispatch(putProjectFailure(e.message));
  }
};

// DELETE /api/project
export const deleteProjectSuccess = (project) => ({ type: types.DELETE_PROJECT_SUCCESS, project });
export const deleteProjectFailure = (errorMessage) => ({ type: types.DELETE_PROJECT_FAILURE, errorMessage });
export const deleteProject = (projectId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the project...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/project/${projectId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_PROJECT_SUCCESS));
    dispatch(deleteProjectSuccess(res.data.project));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_PROJECT_FAILURE)
    );
    dispatch(deleteProjectFailure(e.message));
  }
};
