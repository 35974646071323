/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
///////////////////////////////////////////////////////////////////PROJECTS DEFAULT STATE
const projectsDefaultState = [];
/////////////////////////////////////////////////////////////////////////PROJECTS REDUCER
const projects = (state = projectsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_PROJECTS_SUCCESS:
      return Array.isArray(action.projects) ? [...action.projects] : [action.projects];
    case types.GET_PROJECTS_FAILURE:
      return [...state];

    // POST request
    case types.POST_PROJECT_SUCCESS:
      return [...state, action.project];
    case types.POST_PROJECT_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_PROJECT_SUCCESS:
      return state.map((project) => {
        if (project._id === action.project._id) {
          return { ...project, ...action.project };
        } else {
          return project;
        }
      });
    case types.PUT_PROJECT_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_PROJECT_SUCCESS:
      return state.filter((project) => project._id !== action.project._id);
    case types.DELETE_PROJECT_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default projects;
