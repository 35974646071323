///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////////PAGES
import HomePage from "../components/Pages/HomePage";
import AuthPage from "../components/Pages/Authentication/AuthPage";
import QuotesPage from "../components/Pages/QuotesPage";
import ProjectsPage from "../components/Pages/ProjectsPage";
import ProspectsPage from "../components/Pages/ProspectsPage";
import AdminPage from "../components/Pages/AdminPage";
import PrivacyPolicyPage from "../components/Pages/Legals/PrivacyPolicyPage";
import CookiesPolicyPage from "../components/Pages/Legals/CookiesPolicyPage";
import TermsConditions from "../components/Pages/Legals/TermsConditions";
import DisclaimerPage from "../components/Pages/Legals/DisclaimerPage";
import ReturnPolicyPage from "../components/Pages/Legals/ReturnPolicyPage";
import NotFoundPage from "../components/Pages/NotFoundPage";
import FailedLoginPage from "../components/Pages/Authentication/FailedLoginPage";
import SuccessfulLoginPage from "../components/Pages/Authentication/SuccessfulLoginPage";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../utils/constants";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from "../components/Layout/Header";
import Alert from "../components/Layout/Alert";
import ChatWidget from "../components/Layout/ChatWidget";
import Footer from "../components/Layout/Footer";
import ScrollUp from "../components/Layout/ScrollUp";
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollUp />
      <Header />
      <Alert />
      <Routes>
        {/* Pages */}
        <Route exact path="/" element={<HomePage />} />
        {c.subscriptions.quotes && <Route exact path="/quotes" element={<QuotesPage />} />}
        {c.subscriptions.projects && <Route exact path="/projects" element={<ProjectsPage />} />}
        {c.subscriptions.prospects && <Route exact path="/prospects" element={<ProspectsPage />} />}
        {c.subscriptions.admin && <Route exact path="/admin" element={<AdminPage />} />}
        {/* Auth Pages */}
        {c.subscriptions.admin && <Route exact path="/auth" element={<AuthPage />} />}
        {c.subscriptions.admin && <Route exact path="/failed-login" element={<FailedLoginPage />} />}
        {c.subscriptions.admin && <Route exact path="/successful-login" element={<SuccessfulLoginPage />} />}
        {/* Legal Pages */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/cookie-policy" element={<CookiesPolicyPage />} />
        <Route exact path="/terms-conditions" element={<TermsConditions />} />
        <Route exact path="/disclaimer" element={<DisclaimerPage />} />
        <Route exact path="/return-policy" element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <ChatWidget />
    </>
  </BrowserRouter>
);

export default AppRouter;
