///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/worker
export const getWorkersSuccess = (workers) => ({ type: types.GET_WORKERS_SUCCESS, workers });
export const getWorkersFailure = (errorMessage) => ({ type: types.GET_WORKERS_FAILURE, errorMessage });
export const getWorkers = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/worker` : `${resolveHost()}/api/worker${endpoint}`
    );

    dispatch(getWorkersSuccess(res.data));
  } catch (e) {
    dispatch(getWorkersFailure(e.message));
  }
};

// POST /api/worker
export const postWorkerSuccess = (worker) => ({ type: types.POST_WORKER_SUCCESS, worker });
export const postWorkerFailure = (errorMessage) => ({ type: types.POST_WORKER_FAILURE, errorMessage });
export const postWorker = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the worker...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/worker`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_WORKER_SUCCESS));
    dispatch(postWorkerSuccess(res.data.worker));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_WORKER_FAILURE));
    dispatch(postWorkerFailure(e.message));
  }
};

// PUT /api/worker
export const putWorkerSuccess = (worker) => ({ type: types.PUT_WORKER_SUCCESS, worker });
export const putWorkerFailure = (errorMessage) => ({ type: types.PUT_WORKER_FAILURE, errorMessage });
export const putWorker = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the worker...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/worker/${data.workerId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_WORKER_SUCCESS));
    dispatch(putWorkerSuccess(res.data.worker));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_WORKER_FAILURE));
    dispatch(putWorkerFailure(e.message));
  }
};

// DELETE /api/worker
export const deleteWorkerSuccess = (worker) => ({ type: types.DELETE_WORKER_SUCCESS, worker });
export const deleteWorkerFailure = (errorMessage) => ({ type: types.DELETE_WORKER_FAILURE, errorMessage });
export const deleteWorker = (workerId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the worker...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/worker/${workerId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_WORKER_SUCCESS));
    dispatch(deleteWorkerSuccess(res.data.worker));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_WORKER_FAILURE)
    );
    dispatch(deleteWorkerFailure(e.message));
  }
};
