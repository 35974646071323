////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "../styles";
//////////////////////////////////////////////////////////////////////////////////////////

const Thread = () => {
  // JSX
  return (
    <thead>
      <tr style={styles.trStyles}>
        <th>
          <div style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
            Payment Reference
          </div>
        </th>
        <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
          <div>Due Date</div>
        </th>
        <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
          <div>Status</div>
        </th>
        <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
          <div>Rate</div>
        </th>
      </tr>
    </thead>
  );
};

export default Thread;
