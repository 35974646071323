///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  Spinner,
  Form as FormRS,
  CustomListGroup,
  CustomListGroupItem,
  InputGroup,
  CloseButton,
  Row,
  Col,
  ListGroupItemText,
} from "@ibiliaze/reactstrap";
import { Api, WindowButton } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
import { postQuote, putQuote, deleteQuote } from "../../actions/quotes";
import { pushEmail } from "../../actions/email";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
import c from "../../utils/constants";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Form = ({
  inputs,
  setInputs,
  inputsDefault,
  edit,
  setEdit,
  _ref: ref,
  setRef,
  details,
  setDetails,
  quoteUrl,
  postQuote,
  putQuote,
  deleteQuote,
  setAlert,
  pushEmail,
}) => {
  // State
  const [generated, setGenerated] = useState("");
  const [loading, setLoading] = useState({
    generate: false,
    sendEmail: false,
    saveQuote: false,
    deleteQuote: false,
  });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onGenerateClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, generate: true }));
      const res = await api.post("/api/quote/generate", { ...inputs, template: "generateQuote" });
      setGenerated(res.data.quote);
      setLoading((c) => ({ ...c, generate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, generate: false }));
      console.error(e);
    }
  };

  const onSaveDetailClick = (_) => {
    try {
      if (!!edit.detail) {
        const arr = details;
        arr[edit.detail - 1] = { input: inputs.details, details: generated };

        setDetails((c) => [...arr]);
      } else {
        setDetails((c) => [...c, { input: inputs.details, details: generated, ref: getRandomString(4) }]);
      }

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit((c) => ({ ...c, detail: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onDetailClick = (d, i) => {
    try {
      setEdit((c) => ({ ...c, detail: i + 1 }));
      setInputs((c) => ({ ...c, details: d.input }));
      setGenerated(d.details);
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onDeleteDetailClick = (i) => {
    try {
      const arr = details;
      arr.splice(i, 1);
      setDetails([...arr]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit((c) => ({ ...c, detail: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onEmailClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, sendEmail: true }));

      if (!quoteUrl) {
        return setAlert("Quote has not been created", true, null);
      }

      await pushEmail({
        to: inputs.email,
        subject: `${inputs.invoice ? "Invoice" : "Quote"} from ${c.name}`,
        attachments: [
          {
            filename: `${inputs.invoice ? "Invoice" : "Quote"} for ${inputs.name}${
              !!inputs.tag ? ` - ${inputs.tag}` : ""
            }.pdf`,
            path: quoteUrl,
          },
          ...c.public.attachments,
        ],
        args: { name: inputs.name, invoice: inputs.invoice },
        template: "quote",
      });

      setLoading((c) => ({ ...c, sendEmail: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, sendEmail: false }));
      console.error(e);
    }
  };

  const onDeleteQuoteClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, deleteQuote: true }));
      await deleteQuote(inputs._id);
      setEdit((c) => ({ ...c, quote: false }));
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
      setLoading((c) => ({ ...c, deleteQuote: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, quote: false }));
      setLoading((c) => ({ ...c, deleteQuote: false }));
      console.error(e);
    }
  };

  const onInvoiceClick = async (e) => {
    try {
      setInputs((c) => ({ ...c, invoice: !c.invoice }));
    } catch (e) {
      console.error(e);
    }
  };

  const onAcceptedClick = async (e) => {
    try {
      setInputs((c) => ({ ...c, accepted: !c.accepted }));
    } catch (e) {
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading((c) => ({ ...c, saveQuote: true }));
      const req = edit.quote ? putQuote : postQuote;
      await req({
        quoteId: inputs._id,
        ...inputs,
        ref,
        inputs,
        details,
      });
      setLoading((c) => ({ ...c, saveQuote: false }));
    } catch (e) {
      console.error(e);
      setLoading((c) => ({ ...c, saveQuote: false }));
    }
  };

  // JSX
  return (
    <FormRS onSubmit={onSubmit}>
      <FormGroup floating>
        <CustomInput
          required
          placeholder="Model *"
          type="select"
          name="model"
          value={inputs.model}
          onChange={onInputsChange}
        >
          <option>gpt-3.5-turbo-0125</option>
          <option>gpt-4</option>
        </CustomInput>
        <Label>Model *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Temprature *"
          type="number"
          name="temprature"
          value={inputs.temprature}
          onChange={onInputsChange}
        />
        <Label>Temprature *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Top P *"
          type="number"
          name="top_p"
          value={inputs.top_p}
          onChange={onInputsChange}
        />
        <Label>Top P *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Max Tokens *"
          type="number"
          name="max_tokens"
          value={inputs.max_tokens}
          onChange={onInputsChange}
        />
        <Label>Max Tokens *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput placeholder="Tag" name="tag" value={inputs.tag} onChange={onInputsChange} />
        <Label>Tag</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Name *"
          name="name"
          value={inputs.name}
          onChange={onInputsChange}
        />
        <Label>Name *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Address *"
          name="address"
          value={inputs.address}
          onChange={onInputsChange}
        />
        <Label>Address *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Postcode *"
          name="postcode"
          value={inputs.postcode}
          onChange={onInputsChange}
        />
        <Label>Postcode *</Label>
      </FormGroup>

      <FormGroup>
        <InputGroup>
          <CustomInput
            required
            name="email"
            value={inputs.email}
            onChange={onInputsChange}
            placeholder="recipient@example.com"
          />
          <CustomButton
            disabled={loading.sendEmail ? true : !quoteUrl ? true : false}
            color="primary"
            black
            onClick={onEmailClick}
          >
            {loading.sendEmail ? <Spinner type="grow" size="sm" /> : "Send Email"}
          </CustomButton>
        </InputGroup>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Phone *"
          type="number"
          name="tel"
          value={inputs.tel}
          onChange={onInputsChange}
        />
        <Label>Phone *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Estimated Time *"
          name="time"
          value={inputs.time}
          onChange={onInputsChange}
        />
        <Label>Estimated Time *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput required type="select" name="rci" value={inputs.rci} onChange={onInputsChange}>
          <option>yes</option>
          <option>no</option>
        </CustomInput>
        <Label>Rubbish collection included *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          min={0}
          type="number"
          name="instalments"
          value={inputs.instalments}
          onChange={onInputsChange}
        />
        <Label>Instalments *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          min={0}
          max={100}
          type="number"
          name="depositPercentage"
          value={inputs.depositPercentage}
          onChange={onInputsChange}
        />
        <Label>Deposit Percentage *</Label>
      </FormGroup>

      {inputs.invoice && (
        <FormGroup floating>
          <CustomInput
            required
            type="date"
            name="projectDate"
            value={inputs.projectDate}
            onChange={onInputsChange}
          />
          <Label>Project Start Date</Label>
        </FormGroup>
      )}

      <Row>
        <Col md={4}>
          <br />
          <CustomListGroup>
            {details.map((d, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onDetailClick(d, i)}>
                <ListGroupItemText>
                  {d?.details?.header}{" "}
                  <CloseButton onClick={(_) => onDeleteDetailClick(i)} style={{ float: "right" }} />
                </ListGroupItemText>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
        </Col>
        <Col md={8}>
          <div className="stick-to-top">
            <br />

            <FormGroup>
              <Label>Details</Label>
              <CustomInput
                placeholder="Details"
                type="textarea"
                rows={10}
                name="details"
                value={inputs.details}
                onChange={onInputsChange}
              />
            </FormGroup>

            <div style={{ float: "right" }}>
              <ButtonGroup>
                <CustomButton
                  disabled={loading.generate ? true : false}
                  color="primary"
                  onClick={onGenerateClick}
                >
                  {loading.generate ? <Spinner type="grow" size="sm" /> : "Generate"}
                </CustomButton>
                <CustomButton color="primary" black onClick={onSaveDetailClick}>
                  Save
                </CustomButton>
              </ButtonGroup>
            </div>

            {!!generated && typeof generated === "object" ? (
              <pre>
                {JSON.stringify(generated, null, 2)}
                <br />
              </pre>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>

      <br />
      <br />

      <CustomButton type="submit" disabled={loading.saveQuote ? true : false} color="primary" black>
        {loading.saveQuote ? <Spinner type="grow" size="sm" /> : "Save"}
      </CustomButton>
      {edit.quote && (
        <>
          <CustomButton color="primary" onClick={onInvoiceClick}>
            Turn to {inputs.invoice ? "Quote" : "Invoice"}
          </CustomButton>
          <CustomButton color={inputs.accepted ? "danger" : "success"} onClick={onAcceptedClick}>
            {inputs.accepted ? "Withdrawn" : "Accepted"}
          </CustomButton>
          <CustomButton
            disabled={loading.deleteQuote ? true : false}
            color="danger"
            onClick={onDeleteQuoteClick}
          >
            Delete
          </CustomButton>
        </>
      )}
      <br />
      <br />

      <WindowButton onClick={onSubmit}>
        {loading.saveQuote ? <Spinner type="grow" size="sm" /> : "Save"}
      </WindowButton>
    </FormRS>
  );
};

const mapStateToProps = (state) => ({ quotes: state.quotes });

export default connect(mapStateToProps, { setAlert, postQuote, putQuote, deleteQuote, pushEmail })(Form);
