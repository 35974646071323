///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { FormGroup, Label, CustomButton, CustomInput, Spinner, Form } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Contact = ({ admin, putAdmin }) => {
  // State
  const [inputs, setInputs] = useState({
    email1: "",
    website: "",
    tel1: "",
    tel2: "",
    tel3: "",
    address: "",
    quoteLlc: "",
    logo: "",
  });
  const [loading, setLoading] = useState({ saveSettings: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (admin?.isAuthenticated) {
        const { email1, website, tel1, tel2, tel3, address, quoteLlc, logo } = admin?.admin;
        setInputs((c) => ({ ...c, email1, website, tel1, tel2, tel3, quoteLlc, address, logo }));
      }
    } catch (e) {
      console.error(e);
    }
  }, [admin]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading((c) => ({ ...c, saveSettings: true }));
      await putAdmin({ ...inputs });
      setLoading((c) => ({ ...c, saveSettings: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveSettings: false }));
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <Form onSubmit={onSubmit}>
        <FormGroup floating>
          <CustomInput placeholder="Email" name="email1" value={inputs.email1} onChange={onInputsChange} />
          <Label>Email</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput
            placeholder="Website"
            name="website"
            value={inputs.website}
            onChange={onInputsChange}
          />
          <Label>Website</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput placeholder="Phone 1" name="tel1" value={inputs.tel1} onChange={onInputsChange} />
          <Label>Phone 1</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput placeholder="Phone 2" name="tel2" value={inputs.tel2} onChange={onInputsChange} />
          <Label>Phone 2</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput placeholder="Phone 3" name="tel3" value={inputs.tel3} onChange={onInputsChange} />
          <Label>Phone 3</Label>
        </FormGroup>

        <FormGroup>
          <Label>Address</Label>
          <CustomInput
            type="textarea"
            rows={5}
            placeholder="Address"
            name="address"
            value={inputs.address}
            onChange={onInputsChange}
          />
        </FormGroup>

        <FormGroup floating>
          <CustomInput
            placeholder="Quote LLC"
            name="quoteLlc"
            value={inputs.quoteLlc}
            onChange={onInputsChange}
          />
          <Label>Quote LLC</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput placeholder="Quote Logo" name="logo" value={inputs.logo} onChange={onInputsChange} />
          <Label>Quote Logo</Label>
        </FormGroup>

        <CustomButton type="submit" disabled={loading.saveSettings ? true : false} color="primary" black>
          {loading.saveSettings ? <Spinner type="grow" size="sm" /> : "Save"}
        </CustomButton>
      </Form>
    </>
  );
};

export default Contact;
