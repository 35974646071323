///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/quote
export const getQuotesSuccess = (quotes) => ({ type: types.GET_QUOTES_SUCCESS, quotes });
export const getQuotesFailure = (errorMessage) => ({ type: types.GET_QUOTES_FAILURE, errorMessage });
export const getQuotes = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/quote` : `${resolveHost()}/api/quote${endpoint}`
    );

    dispatch(getQuotesSuccess(res.data));
  } catch (e) {
    dispatch(getQuotesFailure(e.message));
  }
};

// POST /api/quote
export const postQuoteSuccess = (quote) => ({ type: types.POST_QUOTE_SUCCESS, quote });
export const postQuoteFailure = (errorMessage) => ({ type: types.POST_QUOTE_FAILURE, errorMessage });
export const postQuote = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the quote...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/quote`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_QUOTE_SUCCESS));
    dispatch(postQuoteSuccess(res.data.quote));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_QUOTE_FAILURE));
    dispatch(postQuoteFailure(e.message));
  }
};

// PUT /api/quote
export const putQuoteSuccess = (quote) => ({ type: types.PUT_QUOTE_SUCCESS, quote });
export const putQuoteFailure = (errorMessage) => ({ type: types.PUT_QUOTE_FAILURE, errorMessage });
export const putQuote = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the quote...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/quote/${data.quoteId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_QUOTE_SUCCESS));
    dispatch(putQuoteSuccess(res.data.quote));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_QUOTE_FAILURE));
    dispatch(putQuoteFailure(e.message));
  }
};

// DELETE /api/quote
export const deleteQuoteSuccess = (quote) => ({ type: types.DELETE_QUOTE_SUCCESS, quote });
export const deleteQuoteFailure = (errorMessage) => ({ type: types.DELETE_QUOTE_FAILURE, errorMessage });
export const deleteQuote = (quoteId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the quote...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/quote/${quoteId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_QUOTE_SUCCESS));
    dispatch(deleteQuoteSuccess(res.data.quote));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_QUOTE_FAILURE)
    );
    dispatch(deleteQuoteFailure(e.message));
  }
};
