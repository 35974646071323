///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useRef } from "react";
import { connect } from "react-redux";
import { FormGroup, CustomButton, CustomInput, Spinner, InputGroup, ButtonGroup } from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getProjects } from "../../actions/projects";
import { createPdf } from "../../actions/admin";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Form from "../Project/Form";
import Report from "../PDF/Report";
import List from "../Layout/List";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  projectName: "",
  quoteRef: "",
  startDate: "",
  name: "",
  address: "",
  postcode: "",
  details: "",
  price: 0,
  workers: [],
  receipts: [],
  materials: [],
  completed: false,
};
const reportDefault = {
  salaryTotals: { payers: {}, total: 0 },
  receiptTotals: { payers: {}, totalWeSupply: 0, totalCustomerSupplies: 0 },
};

const ProjectsPage = ({ projects, getProjects, createPdf }) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [report, setReport] = useState({ ...reportDefault });
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [loading, setLoading] = useState({ createPdf: false, getProjects: false, calculate: false });
  const [edit, setEdit] = useState({ project: false });
  const [projectNameSearch, setProjectNameSearch] = useState("");
  const [adminView, setAdminView] = useState(true);

  // Ref
  const componentRef = useRef();

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // onChange functions
  const onProjectNameSearchChange = (e) => setProjectNameSearch(e.target.value);

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      e.preventDefault();

      setLoading((c) => ({ ...c, getProjects: true }));
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getProjects(`?projectName=${projectNameSearch}&limit=10&skip=${10 * (goToPage - 1)}`);
      setLoading((c) => ({ ...c, getProjects: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getProjects: false }));
      console.error(e);
    }
  };

  const onSearchClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, getProjects: true }));
      setPage(1);
      await getProjects(`?projectName=${projectNameSearch}&limit=10&skip=0`);
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getProjects: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getProjects: false }));
      console.error(e);
    }
  };

  const onProjectClick = (project) => {
    try {
      if (!!project) {
        setEdit((c) => ({ ...c, project: true }));
        setInputs({ ...project });
      } else {
        setEdit((c) => ({ ...c, project: false }));
        setInputs({ ...inputsDefault });
      }
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, project: false }));
      setInputs({ ...inputsDefault });
      toggleOffcanvas();
    }
  };

  const onCreatePdfClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, createPdf: true }));

      await createPdf({
        inputs,
        dom: componentRef.current.innerHTML,
        docType: "report",
        fileName: `Report for ${inputs.projectName}.pdf`,
      });

      setLoading((c) => ({ ...c, createPdf: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, createPdf: false }));
      console.error(e);
    }
  };

  const onCalculateClick = (_) => {
    try {
      setLoading((c) => ({ ...c, calculate: true }));

      const salaryTotals = { ...reportDefault.salaryTotals };
      const receiptTotals = { ...reportDefault.receiptTotals };
      salaryTotals.payers = {};
      receiptTotals.payers = {};

      // Calculate salaries
      inputs.workers.forEach((entry) => {
        const salary = entry.rate * entry.days;

        salaryTotals.total = salaryTotals.total += salary;

        if (entry.payer) {
          if (salaryTotals.payers[entry.payer]) {
            salaryTotals.payers[entry.payer] += salary;
          } else {
            salaryTotals.payers[entry.payer] = salary;
          }
        }
      });

      // Calculate receipts
      inputs.receipts.forEach((entry) => {
        const amount = Number(entry.amount);
        const payer = entry.paidBy;

        receiptTotals.totalWeSupply =
          entry.suppliedBy === "Us"
            ? (receiptTotals.totalWeSupply += amount)
            : (receiptTotals.totalWeSupply += 0);

        receiptTotals.totalCustomerSupplies =
          entry.suppliedBy === "Customer"
            ? (receiptTotals.totalCustomerSupplies += amount)
            : (receiptTotals.totalCustomerSupplies += 0);

        if (receiptTotals.payers[payer]) {
          receiptTotals.payers[payer] += amount;
        } else {
          receiptTotals.payers[payer] = amount;
        }
      });

      setReport({ salaryTotals: { ...salaryTotals }, receiptTotals: { ...receiptTotals } });

      setLoading((c) => ({ ...c, calculate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, calculate: false }));
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <List
        offcanvas={offcanvas}
        toggleOffcanvas={toggleOffcanvas}
        header="Projects"
        list={projects}
        headerKeys={["projectName"]}
        page={page}
        onPageClick={onPageClick}
        onClick={onProjectClick}
        loading={loading.getProjects}
      />

      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <InputGroup>
            <CustomInput
              placeholder="Search"
              value={projectNameSearch}
              onChange={onProjectNameSearchChange}
            />
            <CustomButton
              disabled={loading.getProjects ? true : false}
              color="primary"
              black
              onClick={onSearchClick}
            >
              {loading.getProjects ? <Spinner type="grow" size="sm" /> : "Search"}
            </CustomButton>
            <CustomButton color="primary" onClick={(_) => onProjectClick()}>
              New Project
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <hr />

        <Form
          edit={edit}
          setEdit={setEdit}
          inputs={inputs}
          setInputs={setInputs}
          inputsDefault={inputsDefault}
        />

        {edit.project && (
          <>
            <ButtonGroup>
              <CustomButton
                disabled={loading.calculate ? true : false}
                color="primary"
                onClick={onCalculateClick}
              >
                {loading.calculate ? <Spinner type="grow" size="sm" /> : "Calculate"}
              </CustomButton>

              <CustomButton color="info" onClick={(_) => setAdminView(!adminView)}>
                {adminView ? "Customer View" : "Admin View"}
              </CustomButton>

              <CustomButton
                disabled={loading.createPdf ? true : false}
                color="primary"
                black
                onClick={onCreatePdfClick}
              >
                {loading.createPdf ? <Spinner type="grow" size="sm" /> : "Create Document"}
              </CustomButton>
            </ButtonGroup>

            <br />
            <br />

            <div className="p-m" style={{ background: "white" }}>
              <div ref={componentRef}>
                <Report
                  adminView={adminView}
                  inputs={{ ...inputs, report: true }}
                  report={report}
                  id={inputs.quoteRef}
                />
              </div>
            </div>

            <br />
          </>
        )}
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ projects: state.projects });

export default connect(mapStateToProps, { getProjects, createPdf })(ProjectsPage);
