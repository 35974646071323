/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
///////////////////////////////////////////////////////////////////WORKERS DEFAULT STATE
const workersDefaultState = [];
/////////////////////////////////////////////////////////////////////////WORKERS REDUCER
const workers = (state = workersDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_WORKERS_SUCCESS:
      return Array.isArray(action.workers) ? [...action.workers] : [action.workers];
    case types.GET_WORKERS_FAILURE:
      return [...state];

    // POST request
    case types.POST_WORKER_SUCCESS:
      return [...state, action.worker];
    case types.POST_WORKER_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_WORKER_SUCCESS:
      return state.map((worker) => {
        if (worker._id === action.worker._id) {
          return { ...worker, ...action.worker };
        } else {
          return worker;
        }
      });
    case types.PUT_WORKER_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_WORKER_SUCCESS:
      return state.filter((worker) => worker._id !== action.worker._id);
    case types.DELETE_WORKER_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default workers;
