///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { WindowButton } from "@ibiliaze/react-base";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  Spinner,
  Form as FormRS,
  InputGroup,
  ButtonGroup,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postProject, putProject, deleteProject } from "../../actions/projects";
import { getWorkers } from "../../actions/workers";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Receipts from "./Receipts";
import Workers from "./Workers";
import Materials from "./Materials";
import Quotes from "./Quotes";
//////////////////////////////////////////////////////////////////////////////////////////

const Form = ({
  workers,
  getWorkers,
  postProject,
  putProject,
  deleteProject,
  edit,
  setEdit,
  inputs,
  setInputs,
  inputsDefault,
}) => {
  // State
  const [loading, setLoading] = useState({ saveProject: false, deleteProject: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onCompletedClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, saveProject: true }));
      setInputs((c) => ({ ...c, completed: !inputs.completed }));
      await putProject({ ...inputs, projectId: inputs._id, completed: !inputs.completed });
      setLoading((c) => ({ ...c, saveProject: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveProject: false }));
      console.error(e);
    }
  };

  const onDeleteProjectClick = async () => {
    try {
      setLoading((c) => ({ ...c, deleteProject: true }));
      await deleteProject(inputs._id);
      setEdit((c) => ({ ...c, project: false }));
      setInputs({ ...inputsDefault });
      setLoading((c) => ({ ...c, deleteProject: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, project: false }));
      setLoading((c) => ({ ...c, deleteProject: false }));
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => {
        await getWorkers();
      })();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.project ? putProject : postProject;

      setLoading((c) => ({ ...c, saveProject: true }));
      await req({ ...inputs, projectId: inputs._id });
      setLoading((c) => ({ ...c, saveProject: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveProject: false }));
      console.error(e);
    }
  };

  // JSX
  return (
    <FormRS onSubmit={onSubmit}>
      <FormGroup>
        <InputGroup>
          <CustomInput
            required
            placeholder="Project Name *"
            name="projectName"
            value={inputs.projectName}
            onChange={onInputsChange}
          />
          {edit.project && (
            <CustomButton color={inputs.completed ? "danger" : "success"} onClick={onCompletedClick}>
              {inputs.completed ? "Incompleted" : "Completed"}
            </CustomButton>
          )}
        </InputGroup>
      </FormGroup>

      <Quotes setInputs={setInputs} quoteRef={inputs?.quoteRef} />

      <FormGroup floating>
        <CustomInput placeholder="Customer Name" name="name" value={inputs.name} onChange={onInputsChange} />
        <Label>Customer Name</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput placeholder="Address" name="address" value={inputs.address} onChange={onInputsChange} />
        <Label>Address</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          placeholder="Postcode"
          name="postcode"
          value={inputs.postcode}
          onChange={onInputsChange}
        />
        <Label>Postcode</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Price *"
          type="number"
          name="price"
          value={inputs.price}
          onChange={onInputsChange}
        />
        <Label>Price *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Start Date *"
          type="date"
          name="startDate"
          value={inputs.startDate}
          onChange={onInputsChange}
        />
        <Label>Start Date *</Label>
      </FormGroup>

      <Workers workers={workers} selectedWorkers={inputs?.workers} setInputs={setInputs} />

      <Receipts workers={workers} receipts={inputs?.receipts} setInputs={setInputs} />

      <Materials materials={inputs?.materials} setInputs={setInputs} />

      <ButtonGroup>
        <CustomButton
          disabled={loading.deleteProject ? true : false}
          color="danger"
          onClick={onDeleteProjectClick}
        >
          Delete
        </CustomButton>
        <CustomButton disabled={loading.saveProject ? true : false} color="primary" black type="submit">
          {loading.saveProject ? <Spinner type="grow" size="sm" /> : "Save"}
        </CustomButton>
      </ButtonGroup>

      <br />
      <br />

      <WindowButton onClick={onSubmit}>
        {loading.saveProject ? <Spinner type="grow" size="sm" /> : "Save"}
      </WindowButton>
    </FormRS>
  );
};

const mapStateToProps = (state) => ({ workers: state.workers });

export default connect(mapStateToProps, { postProject, putProject, deleteProject, getWorkers })(Form);
