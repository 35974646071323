///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  ButtonGroup,
  CustomButton,
  CustomInput,
  CustomListGroup,
  CustomListGroupItem,
  Form,
  FormGroup,
  Label,
  ListGroupItemHeading,
  Spinner,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getWorkers, postWorker, putWorker, deleteWorker } from "../../actions/workers";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = { name: "", id: "", ownership: 0, payer: false };

const Workers = ({ workers, getWorkers, postWorker, putWorker, deleteWorker }) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [edit, setEdit] = useState({ worker: false });
  const [loading, setLoading] = useState({ saveWorker: false, getWorkers: false, deleteWorker: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onWorkerClick = (w) => {
    try {
      setInputs((c) => ({ ...c, ...w }));
      setEdit((c) => ({ ...c, worker: true }));
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteWorkerClick = async () => {
    try {
      setLoading((c) => ({ ...c, deleteWorker: true }));
      await deleteWorker(inputs._id);
      setEdit((c) => ({ ...c, worker: false }));
      setInputs({ ...inputsDefault });
      setLoading((c) => ({ ...c, deleteWorker: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, worker: false }));
      setLoading((c) => ({ ...c, deleteWorker: false }));
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => {
        await getWorkers();
      })();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.worker ? putWorker : postWorker;

      setLoading((c) => ({ ...c, saveWorker: true }));
      await req({ ...inputs, workerId: inputs._id });
      setLoading((c) => ({ ...c, saveWorker: false }));

      setEdit((c) => ({ ...c, worker: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      setLoading((c) => ({ ...c, saveWorker: false }));
      setEdit((c) => ({ ...c, worker: false }));
      setInputs({ ...inputsDefault });
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <Form onSubmit={onSubmit}>
        <FormGroup floating>
          <CustomInput
            required
            placeholder="Name *"
            name="name"
            value={inputs.name}
            onChange={onInputsChange}
          />
          <Label>Name *</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput required placeholder="ID *" name="id" value={inputs.id} onChange={onInputsChange} />
          <Label>ID *</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput
            required
            type="number"
            placeholder="Ownership *"
            name="ownership"
            value={inputs.ownership}
            onChange={onInputsChange}
          />
          <Label>Ownership *</Label>
        </FormGroup>

        <FormGroup>
          <ButtonGroup>
            {edit.worker && (
              <CustomButton
                disabled={loading.deleteWorker ? true : false}
                color="danger"
                onClick={onDeleteWorkerClick}
              >
                Delete
              </CustomButton>
            )}
            <CustomButton onClick={(e) => setInputs((c) => ({ ...c, payer: !inputs.payer }))}>
              {inputs.payer ? "Non-payer" : "Payer"}
            </CustomButton>
            <CustomButton disabled={loading.saveWorker ? true : false} color="primary" black type="submit">
              {loading.saveWorker ? <Spinner type="grow" size="sm" /> : "Save"}
            </CustomButton>
          </ButtonGroup>
        </FormGroup>
      </Form>

      <CustomListGroup>
        {workers?.map((w, i) => (
          <CustomListGroupItem key={i} onClick={(_) => onWorkerClick(w)}>
            <ListGroupItemHeading>{w.name}</ListGroupItemHeading>
          </CustomListGroupItem>
        ))}
      </CustomListGroup>
    </>
  );
};

const mapStateToProps = (state) => ({ workers: state.workers });

export default connect(mapStateToProps, { getWorkers, postWorker, putWorker, deleteWorker })(Workers);
