///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { connect } from "react-redux";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Thread from "./Thread";
import Deposit from "./Deposit";
import Payment from "./Payment";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { pushEmail } from "../../../actions/email";
//////////////////////////////////////////////////////////////////////////////////////////

const Invoice = ({ total, inputs, setInputs, id, quoteUrl, pushEmail }) => {
  // State
  const [showInputs, setShowInputs] = useState({});
  const [paymentInputs, setPaymentInputs] = useState({ paymentDate: "TBC", paymentPaid: false });

  // onChange functions
  const onQuoteInputsChange = (e) => setPaymentInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPaymentClick = (paymentRef) => {
    try {
      setShowInputs((c) => ({ [paymentRef]: true }));
      setPaymentInputs((c) => ({
        ...c,
        paymentDate: inputs[`${paymentRef}-date`],
        paymentPaid: inputs[`${paymentRef}-paid`],
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const onPaymentPaidClick = (_) => {
    try {
      setPaymentInputs((c) => ({ ...c, paymentPaid: !paymentInputs.paymentPaid }));
    } catch (e) {
      console.error(e);
    }
  };

  const onSavePaymentClick = (paymentRef) => {
    try {
      setShowInputs((c) => ({ ...c, [paymentRef]: false }));
      setInputs((c) => ({
        ...c,
        [`${paymentRef}-date`]: paymentInputs.paymentDate,
        [`${paymentRef}-paid`]: paymentInputs.paymentPaid,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <Thread />

      <tbody>
        <Deposit
          id={id}
          inputs={inputs}
          total={total}
          paymentInputs={paymentInputs}
          showInputs={showInputs}
          onQuoteInputsChange={onQuoteInputsChange}
          onPaymentClick={onPaymentClick}
          onPaymentPaidClick={onPaymentPaidClick}
          onSavePaymentClick={onSavePaymentClick}
        />

        {Number(inputs?.instalments) === 0
          ? []
          : Array(Number(inputs?.instalments))
              .fill(null)
              .map((_, index) => {
                return `Iteration ${index + 1}`;
              })
              .map((_, i) => (
                <Payment
                  key={i}
                  i={i}
                  id={id}
                  inputs={inputs}
                  total={total}
                  paymentInputs={paymentInputs}
                  showInputs={showInputs}
                  quoteUrl={quoteUrl}
                  onQuoteInputsChange={onQuoteInputsChange}
                  onPaymentClick={onPaymentClick}
                  onPaymentPaidClick={onPaymentPaidClick}
                  onSavePaymentClick={onSavePaymentClick}
                  pushEmail={pushEmail}
                />
              ))}
      </tbody>
    </>
  );
};

export default connect(null, { pushEmail })(Invoice);
