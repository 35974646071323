///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomButton, CustomInput, InputGroup } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const getDepositId = (id, inputs) => {
  try {
    return `${id}-${inputs?.instalments > 0 ? "deposit" : "1"}`;
  } catch (e) {
    console.error(e);
    return "deposit";
  }
};

const Invoice = ({
  total,
  inputs,
  showInputs,
  paymentInputs,
  id,
  onQuoteInputsChange,
  onPaymentClick,
  onPaymentPaidClick,
  onSavePaymentClick,
}) => {
  // JSX
  return (
    <tr>
      {/* REF */}
      <td style={{ cursor: "pointer" }} onClick={(_) => onPaymentClick(getDepositId(id, inputs))}>
        <div>{getDepositId(id, inputs)}</div>
      </td>

      {/* DATE */}
      <td style={{ textAlign: "center", verticalAlign: "top" }}>
        {showInputs[getDepositId(id, inputs)] ? (
          <InputGroup>
            <CustomInput
              value={paymentInputs.paymentDate}
              onChange={onQuoteInputsChange}
              name="paymentDate"
              type="date"
            />
            <CustomButton onClick={onPaymentPaidClick} color="primary">
              {paymentInputs.paymentPaid ? "Unpaid" : "Paid"}
            </CustomButton>
            <CustomButton onClick={(_) => onSavePaymentClick(getDepositId(id, inputs))} black color="primary">
              Save
            </CustomButton>
          </InputGroup>
        ) : !!inputs[`${getDepositId(id, inputs)}-date`] ? (
          new Date(inputs[`${getDepositId(id, inputs)}-date`]).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        ) : (
          "TBC"
        )}
      </td>

      {/* STATUS */}
      <td style={{ textAlign: "center", verticalAlign: "top" }}>
        {inputs[`${getDepositId(id, inputs)}-paid`] ? "Paid" : "Unpaid"}
      </td>

      {/* RATE */}
      <td style={{ textAlign: "right", verticalAlign: "top" }}>
        <b>£{(total / 100) * Number(inputs?.depositPercentage)?.toFixed(2)}</b>
      </td>
    </tr>
  );
};

export default Invoice;
