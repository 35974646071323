///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { FormGroup, Label, CustomButton, CustomInput, Spinner, Form } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Contact = ({ admin, putAdmin }) => {
  // State
  const [inputs, setInputs] = useState({ bankAccName: "", bankAccNumber: "", bankSortcode: "" });
  const [loading, setLoading] = useState({ saveSettings: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (admin?.isAuthenticated) {
        const { bankAccName, bankAccNumber, bankSortcode } = admin?.admin;
        setInputs((c) => ({ ...c, bankAccName, bankAccNumber, bankSortcode }));
      }
    } catch (e) {
      console.error(e);
    }
  }, [admin]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading((c) => ({ ...c, saveSettings: true }));
      await putAdmin({ ...inputs });
      setLoading((c) => ({ ...c, saveSettings: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveSettings: false }));
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <Form onSubmit={onSubmit}>
        <FormGroup floating>
          <CustomInput
            placeholder="Bank Account Name"
            name="bankAccName"
            value={inputs.bankAccName}
            onChange={onInputsChange}
          />
          <Label>Bank Account Name</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput
            placeholder="Account Number"
            name="bankAccNumber"
            value={inputs.bankAccNumber}
            onChange={onInputsChange}
          />
          <Label>Account Number</Label>
        </FormGroup>

        <FormGroup floating>
          <CustomInput
            placeholder="Sort Code"
            name="bankSortcode"
            value={inputs.bankSortcode}
            onChange={onInputsChange}
          />
          <Label>Sort Code</Label>
        </FormGroup>

        <CustomButton type="submit" disabled={loading.saveSettings ? true : false} color="primary" black>
          {loading.saveSettings ? <Spinner type="grow" size="sm" /> : "Save"}
        </CustomButton>
      </Form>
    </>
  );
};

export default Contact;
