///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { FormGroup, CustomButton, CustomInput, Label } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Google = ({ googleLogin, googleBackup, restore }) => {
  // State
  const [inputs, setInputs] = useState({ adminKey: "" });
  const [loading, setLoading] = useState({ backupRestore: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onGoogleLoginClick = async (e) => {
    try {
      await googleLogin();
    } catch (e) {
      console.error(e);
    }
  };

  const onBackupClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, backupRestore: true }));
      await googleBackup({
        ...inputs,
        callback: async () => {
          setInputs((c) => ({ ...c, adminKey: "" }));
          setLoading((c) => ({ ...c, backupRestore: false }));
        },
      });
    } catch (e) {
      console.error(e);
      setLoading((c) => ({ ...c, backupRestore: false }));
    }
  };

  const onRestoreClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, backupRestore: true }));
      await restore({
        ...inputs,
        callback: async () => {
          setInputs((c) => ({ ...c, adminKey: "" }));
          setLoading((c) => ({ ...c, backupRestore: false }));
        },
      });
    } catch (e) {
      console.error(e);
      setLoading((c) => ({ ...c, backupRestore: false }));
    }
  };

  // JSX
  return (
    <>
      <FormGroup>
        <CustomButton color="primary" black onClick={onGoogleLoginClick}>
          Google Login
        </CustomButton>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          value={inputs.adminKey}
          onChange={onInputsChange}
          name="adminKey"
          placeholder="Admin Key"
        />
        <Label>Admin Key</Label>
      </FormGroup>

      <FormGroup>
        <CustomButton disabled={loading.backupRestore ? true : false} color="primary" onClick={onBackupClick}>
          Backup to Google Drive
        </CustomButton>
        <CustomButton disabled={loading.backupRestore ? true : false} onClick={onRestoreClick}>
          Restore from backup
        </CustomButton>
      </FormGroup>
    </>
  );
};

export default Google;
