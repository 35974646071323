export default ({ scroll = () => {}, IOS17Filled = () => {} }) => {
  const configObj = {
    // GENERAL INFO
    name: "Eight Mile",
    domain: "eightmile.co.uk",
    slogan: "Most Reliable Information Solutions",
    api: {
      apiUrl: "https://www.eightmile.co.uk",
      apiDevUrl: "http://localhost:5002",
    },

    // SUBSCRIPTIONS
    subscriptions: {
      admin: true,
      quotes: true,
      projects: true,
      prospects: true,
    },

    // CONTACT
    tel: {
      tel1: "02031055699",
      tel2: "07884246577",
    },
    email: {
      email1: "info@eightmile.co.uk",
    },

    // PUBLIC
    public: {
      favicon:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733799164/Eight%20Mile/public/favicon_nlfegn.ico",
      logo192:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733799164/Eight%20Mile/public/logo192_kn0vnd.png",
      logo512:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733799164/Eight%20Mile/public/logo512_sq8l1v.png",
      "apple-touch-icon":
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733799164/Eight%20Mile/public/apple-touch-icon_escr8q.png",
      logoPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733665079/Eight%20Mile/public/logos/logo_d41bfd.png",
      authPagePic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851545/Eight%20Mile/public/img/auth-page_ipwhra_ls6ju1.jpg",
      albumPlaceholderPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851660/Eight%20Mile/public/img/album-placeholder_sjlxfs_hqahgg.webp",
      checkatradeLogoPic:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851850/Eight%20Mile/public/logos/ctlogo_rkfe6b_xkapkw.webp",
      attachments: [
        {
          filename: "Eight Mile - Public Liability Insurance.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733665153/Eight%20Mile/documents/Eight_Mile_Services_-_Public_Liability_Insurance_p0ftn2.pdf",
        },
        {
          filename: "Warranty from Eight Mile.pdf",
          path: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733665154/Eight%20Mile/documents/Warranty_from_Eight_Mile_Services_dszb9u.pdf",
        },
      ],
    },

    // CHAT
    chat: {
      showWidget: true,
      avatar:
        "https://res.cloudinary.com/dgyewim8j/image/upload/v1733669565/Eight%20Mile/public/img/chat_xl0fej.jpg",
      person: "Ibi",
      tel: "+447884246577",
      message: `Hello there! \n \nHow can we help?`,
      status: `Typically replies within a few minutes`,
    },

    // SOCIALS
    socials: {
      instagram: "eightmile",
    },

    // TYPE EFFECTS
    textType: [
      "Wi-Fi",
      "Home Networks",
      "Enterprise Networks",
      "Automation",
      "DevOps Services",
      "Web Design",
      "Web Development",
      "Wi-Fi Surverys",
    ],

    // HEADER
    nav: {
      blur: "1px",
      logo: "",
      dark: true,
      styles: { height: "40px" },
      fadeOnPaths: ["/"],
    },

    // MAIN PIC
    mainPics: {
      main: {
        carousel: [
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733669816/Eight%20Mile/public/img/misha-feshchak-syCXK9WndqQ-unsplash_ayf1gr.jpg",
            altText: "Wi-Fi",
            key: 1,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733669899/Eight%20Mile/public/img/emile-perron-xrVDYZRGdw4-unsplash_vtdnks.jpg",
            altText: "Web Development & Design",
            key: 2,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733670148/Eight%20Mile/public/img/thisisengineering-mvbtVeRVJzg-unsplash_dhstyn.jpg",
            altText: "Automation Services",
            key: 3,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733670398/Eight%20Mile/public/img/tyler-ekF932HpB4M-unsplash_tsyvlm.jpg",
            altText: "Enterprise Networks",
            key: 4,
          },
        ],
      },
      copy1: {
        imgPath:
          "https://res.cloudinary.com/dgyewim8j/image/upload/v1733670561/Eight%20Mile/public/img/thisisengineering-HeNgAjJS1gA-unsplash_znfl6a.jpg",
        height: "100vh",
        mobileHeight: "100vh",
        parallax: false,
        noShadow: true,
        imgBottom: "0%",
        sloganCenter: true,
        slogan: "Commercial & Domestion Trade You Can Trust!",
      },
    },

    // WORKING HOURS
    workingHrs: {
      days: {
        Mon: { start: 32400000, end: 64800000 },
        Tue: { start: 32400000, end: 64800000 },
        Wed: { start: 32400000, end: 64800000 },
        Thu: { start: 32400000, end: 64800000 },
        Fri: { start: 32400000, end: 64800000 },
        Sat: { start: 32400000, end: 64800000 },
        Sun: { start: 0, end: 0 },
      },
      interval: 1800000,
    },

    // INFOS
    infos: {
      infos1: [
        {
          header: "Wi-Fi",
          body: "Our commitment to excellence drives us to perfect every step of our process, delivering top-tier Wi-Fi installation services that set the standard in the industry.",
          icon: (_) => IOS17Filled({ name: "building", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733669816/Eight%20Mile/public/img/misha-feshchak-syCXK9WndqQ-unsplash_ayf1gr.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Web Development & Design",
          body: "We have invested in every aspect of our process to ensure that we deliver a market-leading approach to web design and development.",
          icon: (_) => IOS17Filled({ name: "home", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733671077/Eight%20Mile/public/img/sigmund-Im_cQ6hQo10-unsplash_dkzfbh.jpg",
          alt: "infos1-2",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Automation Services",
          body: "Every detail matters to us. That’s why we’ve refined every aspect of our approach to provide industry-leading automation services, ensuring excellence in every solution we deliver.",
          icon: (_) => IOS17Filled({ name: "money", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1733670148/Eight%20Mile/public/img/thisisengineering-mvbtVeRVJzg-unsplash_dhstyn.jpg",
          alt: "infos1-3",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
      ],
      infos2: [
        {
          header: "Warranty Included",
          body: "We take pride in the reliability and quality of our IT services, ensuring they meet the highest standards and exceed client expectations. That’s why we offer a 1-year warranty on all IT solutions and services provided. If any issues arise within 12 months of delivery due to workmanship or performance, we’ll promptly address them to ensure your complete satisfaction. Our commitment is to deliver solutions that work seamlessly for your business, and we stand by our promise to resolve any concerns swiftly and effectively.",
          icon: (_) => IOS17Filled({ name: "book", size: "40" }),
        },
        {
          header: "Insurance",
          body: "The safety and security of our clients and workers are our top priorities. That's why we take all necessary steps to ensure we are fully insured against any potential accidents or damages. We have comprehensive liability insurance as well as workers' compensation insurance to protect our clients and our team in case of any unforeseen events. We are fully committed to providing you with the highest standards of safety, quality and customer service.",
          icon: (_) => IOS17Filled({ name: "hearts", size: "40" }),
        },
      ],
      infos3: {
        header: "Our Partners",
        body: "",
        partners: [
          {
            header: "Toolstation",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851801/Eight%20Mile/public/logos/tslogo_zdf6vu_ejq1zr.png",
          },
          {
            header: "Wickes",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851792/Eight%20Mile/public/logos/wlogo_f02fzb_m8br3c.png",
          },
          {
            header: "Howdens",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851847/Eight%20Mile/public/logos/hlogo_szpc4p_uhbrzu.png",
          },
          {
            header: "Topps Tiles",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851798/Eight%20Mile/public/logos/ttlogo_onp9cl_gjaa1v.png",
          },
          {
            header: "Screwfix",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851815/Eight%20Mile/public/logos/sflogo_hlgmy6_k37xlr.png",
          },
          {
            header: "Crown",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851853/Eight%20Mile/public/logos/clogo_xdolzp_w83fvh.png",
          },
          {
            header: "BQ",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851856/Eight%20Mile/public/logos/bqlogo_ijvpma_eouyj8.png",
          },
          {
            header: "National Lighting",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851835/Eight%20Mile/public/logos/nllogo_mbkd8r_k5k410.png",
          },
          {
            header: "Travis Perkins",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851812/Eight%20Mile/public/logos/tplogo_rca9pr_icm6b0.png",
          },
          {
            header: "Selco Builders Warehouse",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851817/Eight%20Mile/public/logos/sbwlogo_j2uot8_cqnbpi.png",
          },
          {
            header: "Al-Murad Tiles",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851858/Eight%20Mile/public/logos/amlogo_kkfswo_ymx06d.png",
          },
          {
            header: "KBS",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851844/Eight%20Mile/public/logos/kbslogo_qntazw_v8bplg.png",
          },
          {
            header: "Victorian Plumbing",
            logoSrc:
              "https://res.cloudinary.com/dgyewim8j/image/upload/v1733851796/Eight%20Mile/public/logos/vp_d7egpr_ckfuu5.png",
          },
        ],
      },
    },

    // REVIEWS
    reviews: [
      {
        title: "Wifi extension",
        body: "Our home network does not extend to other rooms. EIGHT MILE SERVICES have completely sorted our problem with a concept called bridging. Theyre very professional and smart",
      },
      {
        title: "Internet for barbershop",
        body: "Eight Mile guys set up a complete WiFi installation at the shop. Our shop is Kochal Barber based in Hackney. Outstanding work and very kind guys",
      },
      {
        title: "Wi- Fi extension",
        body: "Wi-fi in my home was very poor, the service provider tried to help but didn’t resolve anything. Found Eight Miles Services by chance . They came in and surveyed my home, and made some very compelling suggestions. They installed something too clever for me to understand but the end result was that I had Wi-Fi everywhere in my house. I am writing this from the airport where I can finally see my pets remotely, and I am eternally grateful to Ibi for his suggestions on this.",
      },
      {
        title: "great",
        body: "found eight mile swrvices on google. called them, and they managed to help us the next day. needed to setup our smart home with cctv cams, smart lights, ring, smart speakers. perfect service, quick workers, even cleaned up after their work. very happy",
      },
      {
        title: "Wifi",
        body: "I used ibis team before for the bathroom and was told that they also work on internet too. So I used his team for our new Wi-Fi upgrade. Like before, very knowledgeable and simply amazing team. He installed new connections to take full advantage of what oir broadband had to offer Love them. 100% in my recommended list",
      },
      {
        title: "New wifi setup",
        body: "Very intelligent professionals that know everything about their work. Definitely worth the price. Had a roaming issue with newly installed virgin network. Eight Mile team had fixed the problem within a few hours. Will most likelyo use them for our shops wifi upgrade. Also to mention they configured a captive portal system. Extremely impressed",
      },
      {
        title: "Our new Wi-Fi speed has reached new numbers :)",
        body: "Had a recent experience with Ibi and Ilgar for our Wi-Fi installation. Couldn’t be happier with the changes. Before was getting 10mbps, they managed to increase it to almost 100 without installing new routers. Turns out the configuration and the wrong cables were used. They are very knowledgeable in the field and managed to solve the problem in just a few hours. Top lads!",
      },
      {
        title: "WiFi installation/setup",
        body: "I recently had a home network installation, and I couldn't be happier with the results. The technician was professional and knowledgeable, ensuring every device was connected seamlessly. The speed and reliability of the network have improved dramatically, allowing for smooth streaming throughout the entire house. Highly recommend this service to anyone looking to upgrade their home network!",
      },
      {
        title: "Upgrading existing Wi-Fi system",
        body: "The Eight Mile team were very knowledgeable and installed the new W-Fi Access Points with no hiccups. Very courteous amd mission driven.",
      },
      {
        title: "New website with admin",
        body: "Got a website made by the guys at Eight Mile. Complete website with backend and admin accounts. Site is deconstructing.co.uk They took care of everything; from deployments to sourcing the code. They do weekly updates, keeping the site safe and up to date with the latest SEO standards. They also took care of the domains and email domains",
      },
    ],

    // INPUT OPTIONS
    inputsOptions: {
      sortByOptions: [
        { value: "", label: "Select" },
        { value: "createdAt:asc", label: "Oldest" },
        { value: "createdAt:desc", label: "Newest" },
        { value: "updatedAt:asc", label: "Updated - oldest" },
        { value: "updatedAt:desc", label: "Updated - soonest" },
      ],
      prospectTypeOptions: [
        { value: "", label: "Select" },
        { value: "Instagram", label: "Instagram" },
        { value: "Email", label: "Email" },
        { value: "Facebook", label: "Facebook" },
        { value: "TikTok", label: "TikTok" },
        { value: "X", label: "X" },
        { value: "LinkedIn", label: "LinkedIn" },
      ],
      prospectTagOptions: [
        { value: "", label: "Select" },
        { value: "Untagged", label: "Untagged" },
        { value: "Potential", label: "Potential" },
        { value: "Unfit", label: "Unfit" },
      ],
    },

    // EXTRAS
    extras: {
      locations: "London, Belvedere, Orpington, Folkestone, Kensington, Greater London",
      checkatrade: {
        name: "Eight Mile Services",
        urlName: "eightmileservicesltd",
        link: "https://www.checkatrade.com/trades/eightmileservicesltd",
        rating: "10/10 Rating",
      },
    },
  };

  return configObj;
};
