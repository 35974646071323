///////////////////////////////////////////////////////////////////////////////////MODULES
import Marquee from "react-fast-marquee";
import { FadeIn } from "@ibiliaze/react-intersect";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Partners = () => (
  <>
    {!!c.infos.infos3 && (
      <>
        <section className="page p-t-xl p-b-xl">
          <FadeIn>
            <h1 className="t-align-c">{c.infos.infos3.header}</h1>
            <br />
            <h4 className="t-align-c">{c.infos.infos3.body}</h4>
          </FadeIn>
          <hr />
        </section>
        <Marquee speed={40} gradientWidth={50} gradient={false}>
          {c.infos.infos3.partners.map((partner, i) => (
            <img key={i} src={partner.logoSrc} alt="images" className="marquee_img" />
          ))}
        </Marquee>
        <br />
      </>
    )}
  </>
);

export default Partners;
