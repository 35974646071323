///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect, memo } from "react";
////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "./styles";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from "./Header";
//////////////////////////////////////////////////////////////////////////////////////////

const Report = ({ adminView, report, inputs, id }) => {
  // State
  const [details, setDetails] = useState({});
  const [payers, setPayers] = useState({});

  // Lifecycle Hooks
  useEffect(() => {
    try {
      // Initialize an object to store the totals for each payer
      const totalPayments = {};

      // Sum salary totals
      for (const payer in report.salaryTotals.payers) {
        totalPayments[payer] = +Number(
          (totalPayments[payer] || 0) + Number(report.salaryTotals.payers[payer])
        ).toFixed(2);
      }

      // Sum receipt totals
      for (const payer in report.receiptTotals.payers) {
        totalPayments[payer] = +Number(
          (totalPayments[payer] || 0) + Number(report.receiptTotals.payers[payer])
        ).toFixed(2);
      }

      setPayers({ ...totalPayments });

      setDetails({
        receiptTotalWeSupply: +Number(report?.receiptTotals?.totalWeSupply).toFixed(2),
        receiptTotalCustomerSupplies: +Number(report?.receiptTotals?.totalCustomerSupplies).toFixed(2),
        receiptTotal:
          +Number(report?.receiptTotals?.totalWeSupply).toFixed(2) +
          +Number(report?.receiptTotals?.totalCustomerSupplies).toFixed(2),
        salaryTotal: +Number(report?.salaryTotals?.total).toFixed(2),
        profit:
          +Number(inputs.price).toFixed(2) -
          +Number(report?.salaryTotals?.total).toFixed(2) -
          +Number(report?.receiptTotals?.totalWeSupply).toFixed(2),
      });
    } catch (e) {
      setDetails({});
      setPayers({});
      console.error(e);
    }
  }, [report]);

  // JSX
  return (
    <div
      style={{
        background: "white",
        ...styles.textStyles,
      }}
    >
      <Header inputs={inputs} id={id} />

      {/* Profit & Loss */}
      {adminView && (
        <>
          <div style={{ paddingBottom: "70px" }} />
          <div style={{ fontSize: "26px", textAlign: "center", ...styles.hStyles }}>Profit & Loss</div>
          <table borderless style={{ ...styles.tableStyles, ...styles.textStyles }}>
            <thead>
              <tr style={styles.trStyles}>
                <th style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Item
                </th>
                <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  We pay
                </th>
                <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Customer pays
                </th>
                <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "left", fontSize: "17px", verticalAlign: "top" }}>Receipts</td>
                <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>
                  £{details.receiptTotalWeSupply}
                </td>
                <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>
                  £{details.receiptTotalCustomerSupplies}
                </td>
                <td style={{ textAlign: "right", fontSize: "17px", verticalAlign: "top" }}>
                  £{details.receiptTotal}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", fontSize: "17px", verticalAlign: "top" }}>Salaries</td>
                <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>
                  £{details.salaryTotal}
                </td>
                <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>£0</td>
                <td style={{ textAlign: "right", fontSize: "17px", verticalAlign: "top" }}>
                  £{details.salaryTotal}
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div style={{ padding: "10px", fontSize: "large", textAlign: "right" }}>
            Price: <b>£{Number(inputs.price).toFixed(2)}</b>
          </div>
          <div style={{ padding: "10px", fontSize: "large", textAlign: "right" }}>
            Salaries: <b>-£{details.salaryTotal}</b>
          </div>
          <div style={{ padding: "10px", fontSize: "large", textAlign: "right" }}>
            Receipts: <b>-£{details.receiptTotalWeSupply}</b>
          </div>
          <div style={{ padding: "10px", ...styles.trStyles, fontSize: "large", textAlign: "right" }}>
            Profit: <b style={{ color: "green" }}>£{details.profit}</b>
          </div>
          <div style={{ padding: "10px", ...styles.trStyles, fontSize: "large", textAlign: "right" }}>
            Margin:{" "}
            <b style={{ color: "green" }}>
              {Number(details.profit / Number(inputs?.price)).toFixed(2) * 100}%
            </b>
          </div>

          {/* Expenses */}
          <div style={{ paddingBottom: "100px" }} />
          <div style={{ fontSize: "26px", textAlign: "center", ...styles.hStyles }}>Expenses Paid By</div>

          <table borderless style={{ ...styles.tableStyles, ...styles.textStyles }}>
            <thead>
              <tr style={styles.trStyles}>
                <th style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Paid By
                </th>
                <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(payers).map((p, i) => (
                <tr key={i}>
                  <td style={{ textAlign: "left", fontSize: "17px", verticalAlign: "top" }}>{p}</td>
                  <td style={{ textAlign: "right", fontSize: "17px", verticalAlign: "top" }}>£{payers[p]}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Salaries */}
          <div style={{ paddingBottom: "100px" }} />
          <div style={{ fontSize: "26px", textAlign: "center", ...styles.hStyles }}>Salaries</div>
          <table borderless style={{ ...styles.tableStyles, ...styles.textStyles }}>
            <thead>
              <tr style={styles.trStyles}>
                <th style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Worker
                </th>
                <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Rate
                </th>
                <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Days
                </th>
                <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Paid by
                </th>
                <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {inputs?.workers?.map((w, i) => (
                <tr key={i}>
                  <td style={{ textAlign: "left", fontSize: "17px", verticalAlign: "top" }}>{w?.id}</td>
                  <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>£{w?.rate}</td>
                  <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>{w?.days}</td>
                  <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>{w?.payer}</td>
                  <td style={{ textAlign: "right", fontSize: "17px", verticalAlign: "top" }}>
                    £{(Number(w?.rate) * Number(w?.days)).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ paddingBottom: "100px" }} />
        </>
      )}

      <div style={{ fontSize: "26px", textAlign: "center", ...styles.hStyles }}>Receipts</div>
      <table borderless style={{ ...styles.tableStyles, ...styles.textStyles }}>
        <thead>
          <tr style={styles.trStyles}>
            <th style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
              Receipt
            </th>
            <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
              Paid by
            </th>
            <th style={{ textAlign: "center", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
              Supplied by
            </th>
            <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {inputs?.receipts?.map(
            (r, i) =>
              (adminView || (!adminView && r?.suppliedBy === "Customer")) && (
                <tr key={i}>
                  <td style={{ textAlign: "left", fontSize: "17px", verticalAlign: "top" }}>
                    <a href={r?.imgUrl}>{r?.name}</a>
                  </td>
                  <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>{r?.paidBy}</td>
                  <td style={{ textAlign: "center", fontSize: "17px", verticalAlign: "top" }}>
                    {r?.suppliedBy}
                  </td>
                  <td style={{ textAlign: "right", fontSize: "17px", verticalAlign: "top" }}>£{r?.amount}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Report);
