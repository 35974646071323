///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomButton, ButtonGroup } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Tags = ({ onTagClick }) => (
  <div className="t-align-c">
    <ButtonGroup>
      <CustomButton color="danger" value="Unfit" onClick={onTagClick}>
        Unfit
      </CustomButton>
      <CustomButton color="primary" value="Potential" onClick={onTagClick}>
        Potential
      </CustomButton>
      <CustomButton color="secondary" value="Untagged" onClick={onTagClick}>
        Untagged
      </CustomButton>
    </ButtonGroup>
    <br />
    <br />
  </div>
);

export default Tags;
