///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { Col, CustomButton, Row } from "@ibiliaze/reactstrap";
import { scroll, Section, Api } from "@ibiliaze/react-base";
import CompareImage from "react-compare-image";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Comparisons = ({ admin }) => {
  // State
  const [comparisons, setComparisons] = useState({});

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (admin?.isAuthenticated) {
        setComparisons((c) => ({ ...admin?.admin?.comparisons }));
      } else {
        (async () => {
          const res = await api.get("api/admin/public?select=comparisons");

          setComparisons(res?.data?.comparisons || {});
        })();
      }
    } catch (e) {
      console.error(e);
    }
  }, [admin?.admin]);

  // JSX
  return (
    <Section className="custom-page p-t-xl p-b-xl">
      <Row>
        {/* Comparison 1 */}
        <Col md={8} className="p-b-l">
          <h2>{comparisons?.comparisonOneTitle}</h2>
          <p>{comparisons?.comparisonOneDescription}</p>
          <CustomButton color="primary" onClick={(_) => window.open(`https://wa.me/${c.tel.tel2}`)}>
            Get your FREE Quote
          </CustomButton>
          <br />
          <br />
          <CustomButton
            black
            color="primary"
            onClick={(_) => scroll(document.getElementById("album-section")?.offsetTop)}
          >
            EXPLORE
          </CustomButton>
        </Col>
        <Col md={4} className="p-b-l">
          <CompareImage
            leftImage={comparisons?.["comparisonOne-1"]}
            rightImage={comparisons?.["comparisonOne-2"]}
          />
        </Col>

        {/* Comparison 2 */}
        <Col md={8} className="p-b-l">
          <h2>{comparisons?.comparisonTwoTitle}</h2>
          <p>{comparisons?.comparisonTwoDescription}</p>
          <CustomButton color="primary" onClick={(_) => window.open(`https://wa.me/${c.tel.tel2}`)}>
            Get your FREE Quote
          </CustomButton>
          <br />
          <br />
          <CustomButton
            black
            color="primary"
            onClick={(_) => scroll(document.getElementById("album-section")?.offsetTop)}
          >
            EXPLORE
          </CustomButton>
        </Col>
        <Col md={4} className="p-b-l">
          <CompareImage
            leftImage={comparisons?.["comparisonTwo-1"]}
            rightImage={comparisons?.["comparisonTwo-2"]}
          />
        </Col>
      </Row>
    </Section>
  );
};

export default Comparisons;
