///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { Footer as CustomFooter } from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
//////////////////////////////////////////////////////////////////////////////PACKAGE JSON
import packageJson from "../../../package.json";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = () => {
  // State
  const [contact, setContact] = useState({});

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => {
        const { email1, tel1, tel2 } = (await api.get("api/admin/public?select=email1,tel1,tel2"))?.data;

        setContact((c) => ({ ...c, email1, tel1, tel2 }));
      })();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // JSX
  return (
    <CustomFooter
      title={c.name}
      textButtons={[
        {
          title: `📞 ${contact?.tel1}`,
          onClickHandler: (_) => (window.location.href = `tel:${contact?.tel1}`),
        },
        {
          title: `📞 ${contact?.tel2}`,
          onClickHandler: (_) => (window.location.href = `tel:${contact?.tel2}`),
        },
        {
          title: `📧 ${contact?.email1}`,
          onClickHandler: (_) => (window.location.href = `mailto:${contact?.email1}`),
        },
      ]}
      navLinks={[
        {
          title: "Privacy Policy",
          href: resolveHost() + "/privacy-policy",
        },
        {
          title: "Terms & Conditions",
          href: resolveHost() + "/terms-conditions",
        },
        {
          title: "Disclaimer",
          href: resolveHost() + "/disclaimer",
        },
      ]}
      miniTexts={[`Version: ${packageJson.version}`]}
    />
  );
};

export default Footer;
