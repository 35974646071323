import { scroll, IOS17Filled } from "@ibiliaze/react-base";
import constants from "@ibiliaze/react-constants";
import londonmile from "./configs/londonmile";
import evconstructing from "./configs/evconstructing";
import eightmile from "./configs/eightmile";
import mayesbrooks from "./configs/mayesbrooks";

export const env = process.env;

const config =
  env.REACT_APP_CONFIG === "londonmile"
    ? londonmile
    : env.REACT_APP_CONFIG === "evconstructing"
    ? evconstructing
    : env.REACT_APP_CONFIG === "eightmile"
    ? eightmile
    : env.REACT_APP_CONFIG === "mayesbrooks"
    ? mayesbrooks
    : null;

const c = constants(
  config({
    scroll,
    IOS17Filled: ({ name, size, color }) => <IOS17Filled name={name} size={size} color={color} />,
  })
);

export default c;
