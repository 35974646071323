/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
///////////////////////////////////////////////////////////////////QUOTES DEFAULT STATE
const quotesDefaultState = [];
/////////////////////////////////////////////////////////////////////////QUOTES REDUCER
const quotes = (state = quotesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_QUOTES_SUCCESS:
      return Array.isArray(action.quotes) ? [...action.quotes] : [action.quotes];
    case types.GET_QUOTES_FAILURE:
      return [...state];

    // POST request
    case types.POST_QUOTE_SUCCESS:
      return [...state, action.quote];
    case types.POST_QUOTE_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_QUOTE_SUCCESS:
      return state.map((quote) => {
        if (quote._id === action.quote._id) {
          return { ...quote, ...action.quote };
        } else {
          return quote;
        }
      });
    case types.PUT_QUOTE_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_QUOTE_SUCCESS:
      return state.filter((quote) => quote._id !== action.quote._id);
    case types.DELETE_QUOTE_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default quotes;
