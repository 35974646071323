///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { FormGroup, CustomButton, CustomInput, Spinner, InputGroup } from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getQuotes } from "../../actions/quotes";
import { createPdf } from "../../actions/admin";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Quote from "../PDF/Quote";
import List from "../Layout/List";
import Form from "../Quote/Form";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  // DO NOT CHANGE THESE, BUT YOU CAN ADD
  model: "gpt-4",
  temprature: 1,
  top_p: 0.5,
  max_tokens: 1400,
  tag: "",
  name: "",
  address: "",
  postcode: "",
  time: "",
  details: "",
  email: "",
  tel: "",
  rci: "yes",
  instalments: 3,
  depositPercentage: 15,
  invoice: false,
  accepted: false,
  projectDate: "",
};

const quoteSearchDefault = { name: "", ref: "", postcode: "", email: "", tel: "" };

const QuotesPage = ({ quotes, getQuotes, createPdf }) => {
  // State
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [ref, setRef] = useState("");
  const [quoteUrl, setQuoteUrl] = useState("");
  const [details, setDetails] = useState([]);
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [quoteSearch, setQuoteSearch] = useState({ ...quoteSearchDefault });
  const [quoteSearchType, setQuoteSearchType] = useState("");
  const [edit, setEdit] = useState({ quote: false, detail: false });
  const [loading, setLoading] = useState({ getQuotes: false, pdfCreate: false });

  // Ref
  const componentRef = useRef();

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Location
  const { search } = useLocation();

  // onChange functions
  const onQuoteSearchChange = (e) => setQuoteSearch((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      setLoading((c) => ({ ...c, getQuotes: true }));
      e.preventDefault();
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getQuotes(
        `?${quoteSearchType}=${quoteSearch[quoteSearchType]}&limit=10&skip=${10 * (goToPage - 1)}`
      );
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onQuoteClick = (quote) => {
    try {
      if (!!quote) {
        setEdit((c) => ({ ...c, quote: true }));
        setInputs({ _id: quote._id, ...quote.inputs });
        setDetails([...quote.details]);
        setRef(quote.ref);
      } else {
        setEdit((c) => ({ ...c, quote: false }));
        setInputs({ ...inputsDefault });
        setDetails([]);
        setRef("");
      }
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, quote: false }));
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
      toggleOffcanvas();
    }
  };

  const onPdfClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, pdfCreate: true }));

      await createPdf({
        inputs,
        dom: componentRef.current.innerHTML,
        docType: inputs.invoice ? "invoice" : "quote",
        fileName: `${inputs.invoice ? "Invoice" : "Quote"} for ${inputs.name}${
          !!inputs.tag ? ` - ${inputs.tag}` : ""
        } - ${inputs.postcode} - ${ref}.pdf`,
        callback: setQuoteUrl,
      });

      setLoading((c) => ({ ...c, pdfCreate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, pdfCreate: false }));
      setQuoteUrl("");
      console.error(e);
    }
  };

  const onSearchClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, getQuotes: true }));
      setPage(1);
      await getQuotes(`?${quoteSearchType}=${quoteSearch[quoteSearchType]}&limit=10&skip=0`);
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!edit.quote) {
        setRef(getRandomString(4));
      }
    } catch (e) {
      console.error(e);
    }
  }, [inputs]);

  useEffect(() => {
    try {
      if (!!search) {
        (async () => {
          const res = await api.get(`/api/quote${search}`);
          const quote = res.data[0];

          if (!!quote) {
            setEdit((c) => ({ ...c, quote: true }));
            setInputs({ _id: quote._id, ...quote.inputs });
            setDetails([...quote.details]);
            setRef(quote.ref);
          } else {
            setEdit((c) => ({ ...c, quote: false }));
            setInputs({ ...inputsDefault });
            setDetails([]);
            setRef("");
          }
        })();
      }
    } catch (e) {
      console.error(e);
    }
  }, [search]);

  useEffect(() => {
    setQuoteUrl("");
  }, [inputs, ref, details]);

  // JSX
  return (
    <FullPage>
      <List
        offcanvas={offcanvas}
        toggleOffcanvas={toggleOffcanvas}
        header="Quotes"
        list={quotes}
        headerKeys={["name", "tag"]}
        page={page}
        onPageClick={onPageClick}
        onClick={onQuoteClick}
        loading={loading.getQuotes}
      />

      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <InputGroup>
            <CustomInput
              placeholder="Search"
              name={quoteSearchType}
              value={quoteSearch[quoteSearchType]}
              onChange={onQuoteSearchChange}
            />
            <CustomInput
              type="select"
              value={quoteSearchType}
              onChange={(e) => setQuoteSearchType(e.target.value)}
            >
              <option value="">Select</option>
              {Object.keys(quoteSearchDefault).map((s, i) => (
                <option key={i}>{s}</option>
              ))}
            </CustomInput>
            <CustomButton
              disabled={loading.getQuotes ? true : false}
              color="primary"
              black
              onClick={onSearchClick}
            >
              Search
            </CustomButton>
            <CustomButton color="primary" onClick={(_) => onQuoteClick()}>
              New Quote
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <hr />

        <Form
          inputs={inputs}
          setInputs={setInputs}
          inputsDefault={inputsDefault}
          edit={edit}
          setEdit={setEdit}
          _ref={ref}
          setRef={setRef}
          details={details}
          setDetails={setDetails}
          quoteUrl={quoteUrl}
        />
        <CustomButton disabled={loading.pdfCreate ? true : false} color="primary" onClick={onPdfClick}>
          {loading.pdfCreate ? <Spinner type="grow" size="sm" /> : "Create Document"}
        </CustomButton>

        <br />
        <br />

        <div className="p-m" style={{ background: "white" }}>
          <div ref={componentRef}>
            <Quote details={details} inputs={inputs} setInputs={setInputs} id={ref} quoteUrl={quoteUrl} />
          </div>
        </div>

        <br />
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ quotes: state.quotes });

export default connect(mapStateToProps, { getQuotes, createPdf })(QuotesPage);
