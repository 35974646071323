///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { Api } from "@ibiliaze/react-base";
import {
  Form,
  Col,
  CustomButton,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroupItem,
  FormGroup,
  Label,
  CustomInput,
  ListGroup,
  CloseButton,
} from "@ibiliaze/reactstrap";
import { Section } from "@ibiliaze/react-base";
import { TextType } from "@ibiliaze/react-text-type";
import { DropZone, ImageViewer } from "@ibiliaze/react-dropzone";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Albums = ({ admin, putAdmin, uploadImg }) => {
  // State
  const [inputs, setInputs] = useState({ albumName: "", description: "" });
  const [albums, setAlbums] = useState([]);
  const [modal, setModal] = useState(false);
  const [imgViewer, setImgViewer] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  // Helper functions
  const onUpload = async (file, albumName, i) => {
    try {
      await uploadImg({
        file,
        path: `/${albumName}-${i}?folder=albums`,
        callback: async (imgPath) => {
          setAlbums((prevAlbums) => {
            const updatedAlbums = prevAlbums?.map((album) => {
              if (album.albumName === albumName) {
                return { ...album, images: { ...album.images, [i]: imgPath } };
              } else {
                return album;
              }
            });

            // Update admin state after albums have been updated
            putAdmin({ albums: updatedAlbums });

            return updatedAlbums;
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Toggle functions
  const toggle = (_) => setModal(!modal);
  const toggleImgViewer = (imgSrc) => {
    setImgViewer(!imgViewer);
    setSelectedImg(imgSrc);
  };

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onAddClick = (_) => {
    try {
      setAlbums([...albums, { albumName: inputs.albumName, description: inputs.description, images: {} }]);
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveAlbumClick = (i) => {
    try {
      const splicedAlbums = albums;
      splicedAlbums.splice(i, 1);

      setAlbums([...splicedAlbums]);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (admin?.isAuthenticated) {
        setAlbums((c) => [...admin?.admin?.albums]);
      } else {
        (async () => {
          const res = await api.get("api/admin/public?select=albums");

          setAlbums(res?.data?.albums || []);
        })();
      }
    } catch (e) {
      console.error(e);
    }
  }, [admin?.admin]);

  // onSubmit functions
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await putAdmin({ albums });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Section className="custom-page p-t-xl p-b-xl">
      <h2 className="t-align-c">
        <TextType strings={c.textType}></TextType>

        {!!admin && !!admin.isAuthenticated && (
          <CustomButton color="primary" onClick={toggle}>
            Add Album
          </CustomButton>
        )}
      </h2>

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={onSubmit}>
          <ModalHeader toggle={toggle}>Add Album</ModalHeader>
          <ModalBody>
            <h4 className="t-align-c">Albums</h4>

            <ListGroup>
              {albums?.map((album, i) => (
                <ListGroupItem key={i}>
                  {album.albumName}
                  <CloseButton
                    bsSize="sm"
                    onClick={(_) => onRemoveAlbumClick(i)}
                    style={{ float: "right" }}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
            <br />

            <FormGroup>
              <Label>Account Name</Label>
              <CustomInput
                placeholder="Album Title"
                name="albumName"
                value={inputs.albumName}
                onChange={onInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Description</Label>
              <CustomInput
                type="textarea"
                placeholder="Description"
                name="description"
                value={inputs.description}
                onChange={onInputsChange}
              />
            </FormGroup>

            <CustomButton color="primary" onClick={onAddClick}>
              Add account
            </CustomButton>
          </ModalBody>
          <ModalFooter>
            <CustomButton type="submit" color="primary">
              Save
            </CustomButton>
            <CustomButton onClick={toggle}>Cancel</CustomButton>
          </ModalFooter>
        </Form>
      </Modal>

      {albums?.map((album, i1) => (
        <div key={i1}>
          <h2 className="t-align-c p-t-l">{album.albumName}</h2>
          <h5 className="t-align-c p-b-m">{album.description}</h5>
          <Row style={{ overflow: "hidden" }}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((col, i2) => (
              <Col md={4} key={i2}>
                {admin?.isAuthenticated ? (
                  <DropZone
                    onUpload={(args) => onUpload(args, album?.albumName, i2)}
                    id={`${i1}-${i2}`}
                    imgSrc={album.images[i2.toString()] || c.public.albumPlaceholderPic}
                  />
                ) : (
                  <>
                    <img
                      onClick={(_) =>
                        toggleImgViewer(album?.images[i2.toString()] || c.public.albumPlaceholderPic)
                      }
                      className="change-img-container change-img"
                      alt={`${i1}-${i2}`}
                      src={album?.images[i2.toString()] || c.public.albumPlaceholderPic}
                    />
                    <ImageViewer
                      modal={imgViewer}
                      setModal={setImgViewer}
                      imgSrc={selectedImg}
                      id={`${i1}-${i2}`}
                    />
                  </>
                )}
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Section>
  );
};

export default Albums;
