///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { CustomButton, CustomInput, InputGroup, Spinner, Alert } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const getPaymentId = (id, i) => {
  try {
    return `${id}-${i + 1}`;
  } catch (e) {
    console.error(e);
    return "paymentId";
  }
};

const Payment = ({
  i,
  total,
  inputs,
  id,
  quoteUrl,
  onPaymentClick,
  onPaymentPaidClick,
  onSavePaymentClick,
  onQuoteInputsChange,
  showInputs,
  paymentInputs,
  pushEmail,
}) => {
  // State
  const [loading, setLoading] = useState({ sendEmail: false });
  const [alert, setAlert] = useState({ sendEmail: "" });

  // onClick functions
  const onSendReminderClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, sendEmail: true }));

      if (!quoteUrl) {
        setAlert((c) => ({ ...c, sendEmail: "Quote has not been created" }));
      } else {
        await pushEmail({
          to: inputs.email,
          subject: `Project Payment`,
          attachments: [
            {
              filename: `Invoice for ${inputs.name}${!!inputs.tag ? ` - ${inputs.tag}` : ""}.pdf`,
              path: quoteUrl,
            },
          ],
          args: { name: inputs.name, invoice: inputs.invoice },
          template: "paymentReminder",
        });
      }

      setLoading((c) => ({ ...c, sendEmail: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, sendEmail: false }));
      console.error(e);
    }
  };
  // JSX
  return (
    <tr key={i}>
      {/* REF */}
      <td style={{ cursor: "pointer" }} onClick={(_) => onPaymentClick(getPaymentId(id, i))}>
        <div>{getPaymentId(id, i)}</div>
      </td>

      {/* DATE */}
      <td style={{ textAlign: "center", verticalAlign: "top" }}>
        {showInputs[getPaymentId(id, i)] ? (
          <>
            <InputGroup>
              <CustomInput
                value={paymentInputs.paymentDate}
                onChange={onQuoteInputsChange}
                name="paymentDate"
                type="date"
              />
              <CustomButton onClick={onPaymentPaidClick} color="primary">
                {paymentInputs.paymentPaid ? "Unpaid" : "Paid"}
              </CustomButton>
              <CustomButton
                disabled={loading.sendEmail ? true : false}
                onClick={onSendReminderClick}
                color="success"
              >
                {loading.sendEmail ? <Spinner type="grow" size="sm" /> : " Send Reminder"}
              </CustomButton>

              <CustomButton onClick={(_) => onSavePaymentClick(getPaymentId(id, i))} black color="primary">
                Save
              </CustomButton>
            </InputGroup>

            {!!alert.sendEmail && (
              <>
                <br />
                <Alert className="custom-banner" color="secondary">
                  {alert.sendEmail}
                </Alert>
              </>
            )}
          </>
        ) : !!inputs[`${getPaymentId(id, i)}-date`] ? (
          new Date(inputs[`${getPaymentId(id, i)}-date`]).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        ) : (
          "TBC"
        )}
      </td>

      {/* STATUS */}
      <td style={{ textAlign: "center", verticalAlign: "top" }}>
        {inputs[`${getPaymentId(id, i)}-paid`] ? "Paid" : "Unpaid"}
      </td>

      {/* RATE */}
      <td style={{ textAlign: "right", verticalAlign: "top" }}>
        <b>
          £
          {(
            (total - (total / 100) * Number(inputs?.depositPercentage)) /
            Number(inputs?.instalments)
          ).toFixed(2)}
        </b>
      </td>
    </tr>
  );
};

export default Payment;
